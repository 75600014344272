/* Contenedor principal */
.geoparquesPilares_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding-bottom: 60px;
}

/* Contenedor con ancho limitado (boxed) */
.geoparquesPilares_row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0;
}

/* Cada columna que contiene un card */
.geoparquesPilares_col {
  flex: 0 0 30%;
  padding: 15px;
  display: flex;
  justify-content: center;
  margin: 0;
}

/* Estilo de los cards */
.geoparquesPilares_card {
  background-color: #8FB61C;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Para manejar el efecto flip */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  cursor: pointer;
}

/* Cuando se aplica la clase flipped */
.geoparquesPilares_card.flipped {
  transform: rotateY(180deg);
}

/* Parte frontal del card */
.geoparquesPilares_card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden; /* Esconder al girar */
}

/* Parte trasera del card */
.geoparquesPilares_card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #7A9E12;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  backface-visibility: hidden; /* Esconder cuando no esté en la parte frontal */
  transform: rotateY(180deg);
}

/* Estilo del ícono (imagen SVG) */
.geoparquesPilares_icon {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

/* Estilo del título */
.geoparquesPilares_title {
  color: white;
  font-size: 1.2rem;
  margin: 0;
}

/* Estilo del texto de la parte trasera */
.geoparquesPilares_description {
  font-size: 1.1rem;
  color: white;
  text-align: center;
}

/* ----- Estilos Responsivos ----- */

/* Dispositivos de tabletas pequeñas (max-width: 1024px) */
@media (max-width: 1024px) {
  .geoparquesPilares_col {
    flex: 0 0 30%;
  }

  .geoparquesPilares_card {
    max-width: 250px;
    height: 250px;
  }

  .geoparquesPilares_icon {
    width: 70px;
  }
}

/* Dispositivos móviles (max-width: 768px) */
@media (max-width: 768px) {
  .geoparquesPilares_col {
    flex: 0 0 100%;
  }

  .geoparquesPilares_card {
    max-width: 220px;
    height: 220px;
  }

  .geoparquesPilares_icon {
    width: 60px;
  }

  .geoparquesPilares_title {
    font-size: 1.1rem;
  }
}

/* Dispositivos móviles más pequeños (max-width: 480px) */
@media (max-width: 480px) {
  .geoparquesPilares_col {
    
    flex: 0 0 100%;
  }

  .geoparquesPilares_card {
    max-width: 80%;
    height: 220px;
  }

  .geoparquesPilares_icon {
    width: 50px;
  }

  .geoparquesPilares_title {
    font-size: 1rem;
  }
}

.mt-30 {
  margin-top: 64px;
}

