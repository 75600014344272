/* Ajustes generales */
.main-content {
    padding: 0;
    margin: 0;
    position: relative;
    box-sizing: border-box;
}

.image-grid {
    margin: 20px; /* Ajusta el valor según el espacio que quieras */
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espacio entre las imágenes */
    justify-content: flex-start; /* Ajusta el alineamiento horizontal */
}

/* Títulos y subtítulos */
.subtitulo-page {
    text-align: center;
    margin: 40px auto;
}

/* Contenedor del slider de fondo */
.hero-slider-container-estado {
    position: relative;
    height: 400px;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Estilo del contenido del slider */
.content h1 {
    color: white;
}

/* Contenedor de cada imagen */
.image-container {
    flex: 1 1 calc(25% - 10px); /* Ajusta el espacio y número de columnas */
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box; /* Asegúrate de que el padding y border no aumenten el tamaño */
    height: 200px; /* Ajusta la altura para que las imágenes se ajusten en un contenedor con altura fija */
}

/* Asegúrate de que la imagen ocupe todo el contenedor */
.image-lazy-estado {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra el contenedor */
    display: block; /* Evita el espacio debajo de la imagen */
}

/* Overlay para las imágenes */
.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    z-index: 2; /* Asegúrate de que esté sobre la imagen */
}

/* Texto en la parte inferior izquierda de la imagen */
.image-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 3; /* Asegúrate de que esté sobre el overlay */
}

/* Texto en la parte inferior izquierda de la imagen con fondo */
.image-text-bg {
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    color: white;
    font-size: 1rem;
    background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 1) 100%);
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 2; /* Asegúrate de que esté sobre el overlay */
}

/* Estilo general para el modal */
.modal,
.modal * {
    outline: none; /* Elimina el borde de enfoque azul */
}

/* Modal */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    z-index: 1001; /* Asegúrate de que este valor sea mayor al z-index del modal-overlay */
}

/* Overlay para el modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000; /* Asegúrate de que este valor sea mayor al z-index del select */
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-image {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
}

.download-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #a6208c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.download-button svg {
    margin-right: 5px;
}

/* Estilos generales para el grid de videos */
.video-grid {
    margin: 20px; /* Ajusta el valor según el espacio que quieras */
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espacio entre los videos */
    justify-content: flex-start; /* Ajusta el alineamiento horizontal */
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 400px; /* Ajusta el tamaño máximo del contenedor de video */
    margin: auto;
}

.video-player {
    width: 100%;
    height: auto; /* Ajusta la altura automáticamente */
    border-radius: 10px; /* Ajusta el valor para el radio del borde */
    overflow: hidden; /* Asegura que los bordes redondeados se apliquen correctamente */
}

.video-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px; /* Espacio entre el video y la información */
}

.video-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px; /* Espacio entre el título y el botón */
    text-align: center;
}

.download-button {
    padding: 10px 15px;
    font-size: 14px;
    background-color: #a6208c; /* Ajusta el color de fondo */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #0056b3; /* Ajusta el color de fondo al pasar el cursor */
}

.breadcrumb-button {
    background-color: transparent; /* Fondo transparente */
    border: 2px solid #fff; /* Color del borde, ajusta según tu diseño */
    color: #fff; /* Color del texto, debe coincidir con el color del borde */
    padding: 6px 10px; /* Espaciado interno, ajusta según tu preferencia */
    border-radius: 10px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño del texto */
    cursor: pointer; /* Cursor al pasar sobre el botón */
    transition: all 0.3s ease; /* Transición suave para el hover */
    margin-right: 8px;
}

.breadcrumb-button:hover {
    background-color: none; /* Color de fondo al pasar el mouse */
    color: white; /* Color del texto al pasar el mouse */
}

.breadcrumb-button:focus {
    outline: none; /* Elimina el borde del foco por defecto */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Agrega un contorno cuando el botón está en foco */
}

/* Media Queries */

/* Pantallas grandes (desktops) */
@media (min-width: 1200px) {
    .image-container {
        flex: 1 1 calc(33.33% - 10px); /* 3 columnas en pantallas grandes */
    }
    .video-container {
        flex: 1 1 calc(33.33% - 10px); /* 3 columnas en pantallas grandes */
    }
}

/* Pantallas medianas (tablets) */
@media (min-width: 768px) and (max-width: 1199px) {
    .image-container {
        flex: 1 1 calc(33.33% - 10px); /* 3 columnas en tablets */
    }
    .video-container {
        flex: 1 1 calc(33.33% - 10px); /* 3 columnas en tablets */
    }
}

/* Pantallas pequeñas (móviles) */
@media (max-width: 767px) {
    .image-container {
        flex: 1 1 calc(50% - 10px); /* 2 columnas en móviles */
    }
    .video-container {
        flex: 1 1 calc(50% - 10px); /* 2 columnas en móviles */
    }
}