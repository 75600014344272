.geoparqueSingle-container {
    margin: 0 auto;
    max-width: 1050px;
    padding: 40px 0px;
    border-radius: 10px;
  }
  
  .geoparqueSingle-header-title {
    font-size: var(--txt-title-sm);
	color: #95C41D!important;
	font-family: "Bebas Neue", sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .geoparqueSingle-paragraph {
    padding: 0px 25px 0 25px;
    font-size: 20px;
    line-height: 1.5;
    color: #444;
    margin-bottom: 15px;
  }
  
  .geoparqueSingle-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .geoparqueSingle-list-item {
    font-size: 20px;
    line-height: 1.5;
    color: #444;
    line-height: 1.5;
  }