.experience-aventure-tops-ctn {
  padding: 32px;
}

.experience-aventure-tops-title {
  font-size: var(--txt-title-xl);
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
  color: var(--purple);
  text-align: center;
}

.experience-aventure-tops-ctn-cards {
  display: flex;
  box-sizing: border-box;
  gap: 5%;
  flex-wrap: wrap;
}

.experience-aventure-tops-ctn-card {
  flex: 1 0 15%;
  position: relative;
  overflow: hidden;
}

.experience-aventure-tops-ctn-card:hover .experience-aventure-tops-card-img {
  transform: scale(1.2);
  transition: 0.4s;
}

.experience-aventure-tops-card-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.experience-aventure-tops-ctn-card-position {
  position: absolute;
  top: 0;
  right: 0;
}

.experience-aventure-tops-ctn-card-position-text {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent var(--yellow) transparent transparent;
}

.experience-aventure-tops-card-position-text {
  position: absolute;
  right: 12px;
  top: -62px;
  text-align: right;
  font-size: var(--txt-title-xl);
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 700;
} 

.experience-aventure-tops-ctn-card-description {
 display: none;
}

.experience-aventure-tops-ctn-card:hover .experience-aventure-tops-ctn-card-description {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

