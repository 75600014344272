.call-to-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #03a0af;
  background-image: url('../../assets/img/header/liston-azul.webp');
  height: 80px; /* Altura de barra ajustada */
  position: relative;
  padding: 40px;
}

.call-to-action-button {
  background-color: #007278;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 26px;
  text-transform: uppercase;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  border-radius: 9px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  max-width: 300px; /* Limita el ancho máximo del botón */
  white-space: normal; /* Permite que el texto haga saltos de línea */
  word-wrap: break-word; /* Asegura que el texto no desborde */
}

.call-to-action-button:hover {
  background-color: #005e5c; /* Color más oscuro al pasar el mouse */
}
