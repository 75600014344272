.main-video-top-ctn {
    height: 260px;
    width: 100%;
    aspect-ratio: 9/6;
    object-fit: cover
}
  
.main-img-ribbon {
    width: 100%;
    height: 50px;
    object-fit: cover;
    margin-left: -32px;
}
  
@media screen and (min-width: 744px) {
    .main-video-top-ctn {
      height: 530px;
      width: 100%;
      aspect-ratio: 9/6;
      object-fit: cover
    }
}
  
@media screen and (min-width: 1353px) {
    .main-video-top-ctn {
      height: 1090px;
      width: 100%;
      aspect-ratio: 9/6;
      object-fit: cover;
    }
}

.fullscreen-video-container {
  position: fixed; /* Fija el video en la pantalla */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Coloca el video por detrás de otros elementos */
}

.fullscreen-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}