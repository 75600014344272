.rss-ctn-primary {
  position: fixed;
  top: 140px;
  left: 0;
  z-index: 99;
  display: flex;
  animation-duration: .5s;
  animation-name: rss-in;
}

.rss-ctn-tiktok {
  height: 28px;
  width: 28px;
  background: #000;
  padding: 8px;
  border-radius: 50%;
}

.rss-tiktok {
  width: 100%;
  height: 100%;
}

.rss-ctn-arrows {
  height: 28px;
  width: 28px;
}

.rss-arrows {
  width: 100%;
  height: 100%;
}

.rss-show {
  text-decoration: none;
  color: #fff;
  margin-left: 3px;
  background-color: rgba(0, 0, 0, .9);
  border-radius: 50%;
  padding: 8px;
  border: none;
  cursor: pointer;
}

.hide {
  animation-duration: .5s;
  animation-name: rss-out;
  margin-left: -65px;
}

@keyframes rss-in {
  from {
    margin-left: -65px;
  }
  to {
    margin-left: 0%;
  }
}

@keyframes rss-out {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: -65px;
  }
}

@media (min-width: 744px) {
  .rss-ctn {
    top: 105px;
  }
}