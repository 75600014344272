.muertosAltar2_container {
  
  padding: 0;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto; /* Permite que el contenedor ajuste su altura automáticamente */
  overflow: hidden;
  
}

.muertosAltar2_container svg {
  display: block;  /* Esto elimina el espacio debajo del SVG */
  margin: 0;
  padding: 0;
}

.muertosAltar2_container p {
  color: #000 !important;
}

.muertosAltar_svg {
  width: 100%;  /* Ocupa todo el ancho del contenedor */
  height: auto; /* Mantiene la proporción del SVG */
}

.muertosAltar_btn {
  cursor: pointer;
  transition: opacity 0.2s ease; /* Suaviza la transición para opacidad */
  animation: parpadeo 1.5s infinite; /* Agrega la animación de parpadeo */
  display: inline-block; /* Asegúrate de que el botón no ocupe todo el ancho */
}

/* Nueva animación de parpadeo */
@keyframes parpadeo {
  0%, 100% {
    opacity: 1; /* Opacidad completa al inicio y al final */
  }
  50% {
    opacity: 0.5; /* Opacidad reducida en el medio */
  }
}

/* Estilos para el modal */
.muertosAltar2_container .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}

.muertosAltar2_container .modal-content {
  background: white; /* Fondo blanco para el contenido */
  color: black; /* Texto negro */
  border-radius: 10px; /* Bordes redondeados */
  padding: 20px; /* Espaciado interno */
  max-width: 500px; /* Ancho máximo del modal */
  width: 90%; /* Ancho del modal en porcentaje */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para darle profundidad */
}

/* Estilos para la infoBox */
.muertosAltar_infoBox {
  display: none;
  position: absolute;
}

.muertosAltar_infoBox.show {
  display: block; /* Muestra el cuadro de información al activarse */
}