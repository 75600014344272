/* Estilo para el contenedor de las cards */
.card-container {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-items: center;
    justify-content: center; /* Centra las tarjetas horizontalmente */
    flex-wrap: wrap
  }
  
  /* Estilo base para cada card */
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Efecto hover en la card */
  .card:hover {
    transform: translateY(-10px);
  }
  
  /* Estilo de la imagen de la card */
  .card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #f0f0f0;
  }
  
  /* Estilo del cuerpo de la card */
  .card-body {
    padding: 15px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  /* Estilo del botón */
  .card-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .card-button:hover {
    background-color: #0056b3;
  }
  
  .map-container {
    position: relative;
    display: inline-block;
  }

.map-container iframe {
    border: none;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
}

.content-pm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Dos columnas con igual tamaño */
  gap: 20px; /* Espacio entre columnas */
  padding: 20px;
  margin: 20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(2px);
}

.svg-container{
  position: relative;
  width: 100%; /* Ocupa todo el ancho disponible */
  max-width: 1200px; /* Limita el ancho máximo */
  /* margin: 0 auto; Centra el contenedor */
  padding: 20px;
}
.svg-container svg {
  width: 70%;
  height: auto;
  position: absolute;
  z-index: -1;
  top: 25%;
  left: 0;
  transform: translateY(-50%);
}

.food-container {
  background-image:  url("../../assets/img/icons/food-bg.png");
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center;
  position: relative;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.info-container {
  background-image: url("../../assets/img/icons/bus-bg.png");
  background-repeat: no-repeat;
  background-size:40%;
  background-position: right;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Espacio entre las imágenes */
  margin-top: 20px;
}

.content-img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Opcional: para bordes redondeados */
}

.content-right{
  margin-top: 80px;
}
.image-right{
  margin-top: 25px;
}

@media (max-width: 768px) {
  .content-pm {
    grid-template-columns: 1fr; /* Una sola columna en pantallas pequeñas */
  }
  .map-column iframe {
    height: 300px; /* Ajusta la altura del mapa en móviles */
  }
  .svg-container svg {
    width: 70%; /* Aumenta el tamaño del SVG en pantallas pequeñas */
    top: 30%; /* Ajusta la posición vertical */
  }
  .food-container {
    width: 100%; /* Ocupa todo el ancho disponible */
    height: fit-content;
    background-size:80%;
    background-position: center;
  }
  .content-right{
    margin-top: 0px;
  }
  .image-right{
    margin-top: 0px;
  }
  .container{
    background-image: none;
    width: 100%;
    margin: 15px;
    padding: 0px;
  }
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas más pequeñas */
  }
  .info-container {
    background-size: cover;
  }
}

@media (max-width: 480px) {
  .svg-container svg {
    width: 90%; /* Ocupa casi todo el ancho en pantallas muy pequeñas */
    top: 35%; /* Ajusta la posición vertical */
  }
  .food-container {
    width: 100%; /* Ocupa todo el ancho disponible */
    margin-top: 20px;
    background-size: cover;
  }
  .content-right{
    margin-top: 0px;
  }
  .image-right{
    margin-top: 0px;
  }
  .image-grid {
    grid-template-columns: 1fr; /* 1 columna en pantallas muy pequeñas */
  }
  .info-container {
    background-size: cover;
  }
}