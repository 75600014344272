.geoparques-header-ctn {
	box-sizing: border-box;
	padding: 114px 0 30px 0;
	overflow: hidden;
    
}

.geoparques-header-ctn .geoparques-header-title {
	font-size: var(--txt-title-md);
	color: #95C41D!important;
	font-family: "Bebas Neue", sans-serif;
	letter-spacing: 1px;
	text-align: center;
	margin: 0;
}

.geoparques-header-ctn p {
	margin: 0 auto;
	max-width: 80%;
	text-align: center;
}

.container-geoparques-info {
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 60px;
    transition: opacity 0.5s ease;
}

.geoparques-left-column {
   
    flex: 1;
    width: 50%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar horizontalmente */
}

.container-geoparques-info .geoparques-left-column .lazy-loaded-image-header {
    max-width: 70%; /* Hacer la imagen más pequeña */
    height: auto; /* Mantener la responsividad */
    margin: 0 auto; /* Centrar horizontalmente */
}

.geoparques-right-column {
    flex: 1;
    width: 50%;
    padding-left: 20px;
    padding-right: 80px;
    
}

.geoparques-subtitle {
    font-size: 28px;
    margin-bottom: 10px;
}

.geoparques-paragraph {
    font-size: 20px;
    line-height: 1.5;
    text-align: justify;
}

.geoparques-strong-p {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .container-geoparques-info {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .geoparques-left-column,
    .geoparques-right-column {
        width: 100%;
       
    }

    .geoparques-paragraph {
        text-align: justify;
    }

    .geoparques-right-column ul {
        width: 100%;
    }
    .geoparques-right-column ul li {
        margin-left: 40px;
        margin-right: 40px;
    }

    .geoparques-right-column {
        margin: 0;
        flex: 1;
        width: 100%;
        
    }
}