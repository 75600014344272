.blog-header-ctn {
    box-sizing: border-box;
    padding: 114px 0 30px 0;
    background-image: url('../../assets/img/header/Fondorosa.webp');
    background-repeat: no-repeat;
    background-size: 100% 50px;
    background-position: top;
    overflow: hidden;
  }
  
  .blog-header-ctn .blog-header-title {
    font-size: 45px;
    color: var(--pink);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
  }
  
  .container-blog-categorias {
    display: flex;
    justify-content: space-around; /* Distribución equitativa de las columnas */
    flex-wrap: wrap; /* Envolver los elementos en varias líneas si no caben en una sola línea */
  }
  
  .card-categoria {
    width: 30%; /* Ancho de cada card (ajustar según necesidades) */
    margin-bottom: 20px;
    border-radius: 10px;
    padding-bottom: 20px;
    box-shadow: -1px 1px 15px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: -1px 1px 15px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: -1px 1px 15px 0px rgba(0,0,0,0.4);
    overflow: hidden; /* Ocultar el desbordamiento para la sombra */
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-title {
    text-align: center;
    margin: 10px 0;
    color: #303030!important;
  }
  
  .card-categoria .ver-mas-button {
    text-align: center;
    text-decoration: none;
    display: block;
    margin: 0 auto;
    width: 50%;
    padding: 10px;
    background-color: var(--pink);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Estilos para dispositivos móviles */
  @media screen and (max-width: 768px) {
    .container-blog-categorias {
        padding: 10px;
    }
    .card-categoria {
      width: 100%; /* Cambiar el ancho a 100% en pantallas pequeñas */
    }
  
    /* Opcional: Puedes ajustar el margen entre las tarjetas para evitar que se superpongan */
    .card-categoria:not(:last-child) {
      margin-bottom: 20px; /* Ajustar margen inferior entre las tarjetas */
    }
  
    .card-image {
      height: 200px; /* Reducir la altura de la imagen en pantallas pequeñas */
    }
  
    .ver-mas-button {
      width: 80%; /* Ajustar el ancho del botón en pantallas pequeñas */
    }
  }
  