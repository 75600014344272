.geoparque-header-ctn {
	padding: 84px 0 84px 0;
	background-size: 100% 50px;
	background-position: top;
	overflow: hidden;
	background-color: #699A43;
}

.geoparque-header-title {
	font-size: var(--txt-title-sm);
	color: #95C41D!important;
	font-family: "Bebas Neue", sans-serif;
	letter-spacing: 1px;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 0;
}

.geoparque-header-ctn p {
	color: white!important;
	margin: 0 auto;
	max-width: 80%;
	text-align: center;
}