.experience-images-sun-and-beach-ctn {
  padding: 50px 0;
  background-image: url('../../assets/img/header/Fondorosa.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
  box-sizing: border-box;
  overflow: hidden;
}

.experience-images-sun-and-beach-ctn-hero {
  background-image: url('../../assets/img/experiences/bg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 32px;
}

.experience-images-sun-and-beach-ctn-hero-description {
  
}

.experience-images-sun-and-beach-img-experience-hero {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.experience-images-sun-and-beach-title {
  text-align: center;
  font-size: var(--txt-title-sm);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  margin-top: 42px;
  color: var(--purple);
}

.experience-images-sun-and-beach-ctn-cards {
  display: flex;
  box-sizing: border-box;
  gap: 24px;
  padding: 32px;
}

.experience-images-sun-and-beach-card {
  min-width: calc(90% - 20px);
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 1s;
}

.experience-images-sun-and-beach-card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  aspect-ratio: 3/4;
}

.experience-images-sun-and-beach-card:hover .experience-images-sun-and-beach-card-img {
  transform: scale(1.2);
  transition: 0.4s;
}

.experience-images-sun-and-beach-ctn-card-description {
  display: none;
}

.experience-images-sun-and-beach-card .experience-images-sun-and-beach-ctn-card-description {
  display:  grid;
  position: absolute;
  z-index: 1;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  top: 0;
  left: 0;
  padding: 12px;
  border-radius: 10px;
  align-content:space-between;
}

.experience-images-sun-and-beach-card:hover .experience-images-sun-and-beach-ctn-card-description {
  display:  grid;
  position: absolute;
  z-index: 1;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  top: 0;
  left: 0;
  padding: 12px;
  border-radius: 10px;
  align-content:space-between;
}

.experience-images-sun-and-beach-card .experience-images-sun-and-beach-ctn-card-description-link {
  display:  grid;
  position: absolute;
  z-index: 1;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  top: 0;
  left: 0;
  padding: 12px;
  border-radius: 10px;
  align-content:space-between;
  text-decoration: none;
}

.experience-images-sun-and-beach-card:hover .experience-images-sun-and-beach-ctn-card-description-link {
  display:  grid;
  position: absolute;
  z-index: 1;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  top: 0;
  left: 0;
  padding: 12px;
  border-radius: 10px;
  align-content:space-between;
  text-decoration: none;
}

.experience-images-sun-and-beach-card-description-title {
  font-size: var(--txt-h5-sm);
  font-weight: 700;
  color: #fff;
  margin: 40% 0 0 0;
}

.experience-images-sun-and-beach-ctn-buttons {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-images-sun-and-beach-buttons {
  border: none;
  cursor: pointer;
  background: transparent;
}

.experience-images-sun-and-beach-buttons-img {
  width: 42px;
  height: 42px;
}

@media screen and (min-width: 744px) {
  .experience-images-sun-and-beach-ctn {
    padding: 90px 0;
    background-size: 100% 90px;
  }
  
  
  .experience-images-sun-and-beach-title {
    font-size: var(--txt-title-md);
  }
  
  .experience-images-sun-and-beach-card {
    min-width: calc(45% - 62px);
  }
  
  .experience-images-sun-and-beach-card .experience-images-sun-and-beach-ctn-card-description-link {
    display: none;
  }

  .experience-images-sun-and-beach-card .experience-images-sun-and-beach-ctn-card-description {
    display:  none;
  }
  
  .experience-images-sun-and-beach-card-description-title {
    font-size: var(--txt-h5-md);
    margin: 40% 0 0 0;
  }
  
}

@media screen and (min-width: 1353px) {
  .experience-images-sun-and-beach-ctn {
    padding: 92px 0;
    background-image: url('../../assets/img/header/Fondorosa.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
  
  .experience-images-sun-and-beach-ctn-hero {
    background-image: url('../../assets/img/experiences/bg.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 32px;
  }
  
  .experience-images-sun-and-beach-ctn-hero-description {
    margin-left: 42px;
  }
  
  .experience-images-sun-and-beach-img-experience-hero {
    width: 40vw;
  }
  
  .experience-images-sun-and-beach-title {
    text-align: center;
    font-size: var(--txt-title-xl);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin-top: 42px;
    color: var(--purple);
  }
  
  .experience-images-sun-and-beach-ctn-images {
    background-image: url('../../assets/img/header/Fondoamarrillo.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
    padding: 220px 32px 32px 32px;
    margin-top: 64px;
  }
  
  .experience-images-sun-and-beach-ctn-img {
    display: grid;
    grid-template-areas: 
    "a a b b"
    "a a c d";
    margin-top: 64px;
    height: 670px;
    gap: 32px;
  }
  
  .experience-images-sun-and-beach-ctn-img-title {
    width: 100%;
  }
  
  .experience-images-sun-and-beach-img-title {
    text-align: center;
    font-size: var(--txt-title-xl);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin: 0;
    color: var(--yellow);
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-0 {
    grid-area: a;
    position: relative;
    height: calc(670px + 32px);
    overflow: hidden;
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-1 {
    grid-area: b;
    position: relative;
    height: calc(670px / 2);
    overflow: hidden;
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-2 {
    grid-area: c;
    position: relative;
    height: calc(670px / 2);
    overflow: hidden;
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-3 {
    grid-area: d;
    position: relative;
    height: calc(670px / 2);
    overflow: hidden;
  }
  
  .experience-images-sun-and-beach-img-experience {
    width: 100%;
    height: 100%;
    aspect-ratio: auto;
    object-fit: cover;
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-0:hover .experience-images-sun-and-beach-ctn-img-experience-description,
  .experience-images-sun-and-beach-ctn-img-experience-1:hover .experience-images-sun-and-beach-ctn-img-experience-description,
  .experience-images-sun-and-beach-ctn-img-experience-2:hover .experience-images-sun-and-beach-ctn-img-experience-description,
  .experience-images-sun-and-beach-ctn-img-experience-3:hover .experience-images-sun-and-beach-ctn-img-experience-description,
  .experience-images-sun-and-beach-ctn-img-experience-4:hover .experience-images-sun-and-beach-ctn-img-experience-description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-0:hover .experience-images-sun-and-beach-img-experience, 
  .experience-images-sun-and-beach-ctn-img-experience-1:hover .experience-images-sun-and-beach-img-experience, 
  .experience-images-sun-and-beach-ctn-img-experience-2:hover .experience-images-sun-and-beach-img-experience, 
  .experience-images-sun-and-beach-ctn-img-experience-3:hover .experience-images-sun-and-beach-img-experience, 
  .experience-images-sun-and-beach-ctn-img-experience-4:hover .experience-images-sun-and-beach-img-experience {
    transform: scale(1.2);
     transition: 0.4s;
  }
  
  .experience-images-sun-and-beach-ctn-img-experience-description {
    display: none;
  }
  
  .experience-images-sun-and-beach-img-experience-description {
    text-align: center;
    font-size: var(--txt-h5-xl);
    font-weight: 700;
    color: #fff;
  }
  
  .experience-images-sun-and-beach-ctn-cards {
    display: flex;
    box-sizing: border-box;
    gap: 24px;
    padding: 32px;
  }
  
  .experience-images-sun-and-beach-card {
    min-width: calc(25% - 48px);
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: all 1s;
  }
  
  .experience-images-sun-and-beach-card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    aspect-ratio: 3/4;
  }
  
  .experience-images-sun-and-beach-card:hover .experience-images-sun-and-beach-card-img {
    transform: scale(1.2);
    transition: 0.4s;
  }
  
  .experience-images-sun-and-beach-ctn-card-description {
    display: none;
  }
  
  .experience-images-sun-and-beach-card:hover .experience-images-sun-and-beach-ctn-card-description {
    display:  grid;
    position: absolute;
    z-index: 1;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    top: 0;
    left: 0;
    padding: 12px;
    border-radius: 10px;
    align-content:space-between;
  }
  
  .experience-images-sun-and-beach-card:hover .experience-images-sun-and-beach-ctn-card-description-link {
    display:  grid;
    position: absolute;
    z-index: 1;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    top: 0;
    left: 0;
    padding: 12px;
    border-radius: 10px;
    align-content:space-between;
    text-decoration: none;
  }
  
  .experience-images-sun-and-beach-card-description-title {
    font-size: var(--txt-h5-xl);
    font-weight: 700;
    color: #fff;
    margin: 0 0 7% 0;
  }
  
  .experience-images-sun-and-beach-ctn-buttons {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .experience-images-sun-and-beach-buttons {
    border: none;
    cursor: pointer;
    background: transparent;
  }
  
  .experience-images-sun-and-beach-buttons-img {
    width: 42px;
    height: 42px;
  }
}
