.maria-islands-gallery-ctn {
	padding: 50px 0 0 0;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	background-image: url('../../assets/img/header/Fondorosa.webp');
	background-repeat: no-repeat;
	background-size: 100% 50px;
	background-position: top;
}

.maria-islands-gallery-ctn-title {
	display: block;
	height: 100%;
	width: 100%;
}

.maria-islands-gallery-title {
	font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 1px;
	color: var(--purple)!important;
	font-size: var(--txt-title-sm);
	text-align: center;
}

.maria-islands-gallery-content-ctn {
	display: block;
	padding: 32px 32px 64px 32px;
	height: 100%;
}


.maria-islands-gallery-content-ctn-img-0 {
	width: 100%;
	height: 100%;
	aspect-ratio: 4/3;
	margin-bottom: 24px;
	position: relative;
}

.maria-islands-gallery-content-ctn-img-1 {
	width: 100%;
	height: 100%;
	aspect-ratio: 4/3;
	margin-bottom: 24px;
	position: relative;
}

.maria-islands-gallery-content-ctn-img-2 {
	width: 100%;
	height: 100%;
	aspect-ratio: 4/3;
	margin-bottom: 24px;
	position: relative;
}

.maria-islands-gallery-content-ctn-img-3 {
	width: 100%;
	height: 100%;
	aspect-ratio: 4/3;
	margin-bottom: 24px;
	position: relative;
}

.maria-islands-gallery-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	aspect-ratio: auto;
	border-radius: 10px;
}

.maria-islands-gallery-content-ctn-img-0 .maria-islands-gallery-ctn-img-experience-description,
.maria-islands-gallery-content-ctn-img-1 .maria-islands-gallery-ctn-img-experience-description,
.maria-islands-gallery-content-ctn-img-2 .maria-islands-gallery-ctn-img-experience-description,
.maria-islands-gallery-content-ctn-img-3 .maria-islands-gallery-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.maria-islands-gallery-content-ctn-img-0:hover .maria-islands-gallery-ctn-img-experience-description,
.maria-islands-gallery-content-ctn-img-1:hover .maria-islands-gallery-ctn-img-experience-description,
.maria-islands-gallery-content-ctn-img-2:hover .maria-islands-gallery-ctn-img-experience-description,
.maria-islands-gallery-content-ctn-img-3:hover .maria-islands-gallery-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maria-islands-gallery-content-ctn-img-0:hover .maria-islands-gallery-image,
.maria-islands-gallery-content-ctn-img-1:hover .maria-islands-gallery-image,
.maria-islands-gallery-content-ctn-img-2:hover .maria-islands-gallery-image,
.maria-islands-gallery-content-ctn-img-3:hover .maria-islands-gallery-image {
	transform: scale(1.2);
 	transition: 0.4s;
}

.maria-islands-gallery-ctn-img-experience-description {
	display: none;
}

.maria-islands-gallery-img-experience-description {
	text-align: center;
	font-size: var(--txt-h5-xl);
	font-weight: 700;
	color: #fff;
}

@media screen and (min-width: 744px) {
	.maria-islands-gallery-ctn {
		padding: 90px 0 0 0;
		background-size: 100% 90px;
	}
	
	.maria-islands-gallery-ctn-title {
		padding: 32px 0;
	}
	
	.maria-islands-gallery-title {
		font-size: var(--txt-title-md);
	}
	
	.maria-islands-gallery-content-ctn {
		display: grid;
		grid-template-areas: 
		"a a b b"
		  "a a c d";
		gap: 32px;
		padding: 32px 32px 64px 32px;
		height: 100%;
	}
	
	
	.maria-islands-gallery-content-ctn-img-0 {
		grid-area: a;
		position: relative;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	
	.maria-islands-gallery-content-ctn-img-1 {
		grid-area: b;
		position: relative;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	
	.maria-islands-gallery-content-ctn-img-2 {
		grid-area: c;
		position: relative;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	
	.maria-islands-gallery-content-ctn-img-3 {
		grid-area: d;
		position: relative;
		  height: calc(670px / 2);
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	
	.maria-islands-gallery-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
		aspect-ratio: auto;
		border-radius: 10px;
	}

	.maria-islands-gallery-content-ctn-img-0 .maria-islands-gallery-ctn-img-experience-description,
	.maria-islands-gallery-content-ctn-img-1 .maria-islands-gallery-ctn-img-experience-description,
	.maria-islands-gallery-content-ctn-img-2 .maria-islands-gallery-ctn-img-experience-description,
	.maria-islands-gallery-content-ctn-img-3 .maria-islands-gallery-ctn-img-experience-description {
		display: none;
	}
	
	.maria-islands-gallery-content-ctn-img-0:hover .maria-islands-gallery-ctn-img-experience-description,
	.maria-islands-gallery-content-ctn-img-1:hover .maria-islands-gallery-ctn-img-experience-description,
	.maria-islands-gallery-content-ctn-img-2:hover .maria-islands-gallery-ctn-img-experience-description,
	.maria-islands-gallery-content-ctn-img-3:hover .maria-islands-gallery-ctn-img-experience-description {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius: 9px;
	}
	
	.maria-islands-gallery-content-ctn-img-0:hover .maria-islands-gallery-image,
	.maria-islands-gallery-content-ctn-img-1:hover .maria-islands-gallery-image,
	.maria-islands-gallery-content-ctn-img-2:hover .maria-islands-gallery-image,
	.maria-islands-gallery-content-ctn-img-3:hover .maria-islands-gallery-image {
		transform: scale(1.2);
		transition: 0.4s;
		border-radius: 9px;
	}
	
	.maria-islands-gallery-ctn-img-experience-description {
		display: none;
	}
	
	.maria-islands-gallery-img-experience-description {
		text-align: center;
		font-size: var(--txt-h5-xl);
		font-weight: 700;
		color: #fff;
	}
}

@media screen and (min-width: 1353px) {
	.maria-islands-gallery-ctn {
		padding: 160px 0 0 0;
		background-size: 100% 160px;
	}
	
	.maria-islands-gallery-title {
		font-size: var(--txt-title-xl);
	}
	
	.maria-islands-gallery-content-ctn {
		height: 670px;
	}
}
