.destination-known-ctn {
  display: block;
  padding: 50px 0;
  background-image: url('../../assets/img/header/Fondorosa.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.destination-known-title {
  font-size: var(--txt-title-sm);
  color: var(--purple);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 64px;
}

.destination-known-ctn-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 32px;
}

.destination-known-ctn-gallery-img {
  flex: 1 0 100%;
}

.destination-known-gallery-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.destination-known-ctn-content {
  display: flex;
}

.destination-known-ctn-description {
  padding: 0 32px;
}

.destination-known-description-subtitle {
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  font-size: var(--txt-title-sm);
  color: var(--yellow);
}

.destination-known-description-text {
  font-size: var(--txt-h5-sm);
  text-align: center;
  line-height: 1.5;
}

.destination-known-description-btn {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
}

.destination-known-description-btn:hover {
  background: var(--black); 
  color: #fff;
}

@media screen and (min-width: 744px) {
  .destination-known-ctn {
    padding: 90px 0;
    background-size: 100% 90px;
  }
  
  .destination-known-title {
    font-size: var(--txt-title-md);
  }
  
  .destination-known-ctn-gallery-img {
    flex: 1 0 40%;
  }
  
  .destination-known-description-subtitle {
    font-size: var(--txt-title-md);
  }
  
  .destination-known-description-text {
    font-size: var(--txt-h5-md);
    text-align: justify;
  }
}

@media screen and (min-width: 1353px) {
  .destination-known-ctn {
    padding: 120px 0 90px 0;
    background-size: 100% 120px;
  }
  
  .destination-known-title {
    font-size: var(--txt-title-md);
  }
  
  .destination-known-ctn-gallery-img {
    flex: 1 0 20%;
  }
  
  .destination-known-description-subtitle {
    font-size: var(--txt-title-xl);
  }

  .destination-known-gallery-img {
    aspect-ratio: 3/4;
  }
  
  .destination-known-description-text {
    font-size: var(--txt-h5-xl);
  }
}
