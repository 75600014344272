.international-events-carousel-video-ctn {
  padding: 190px 0 0 0;
  background-image: url('../../assets/img/header/Fondoamarrillo.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;

  box-sizing: border-box;
	overflow: hidden;
	max-width: 100%;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	position: relative;
}

.international-events-carousel-video-ctn-carousel {
	height: 650px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 0 7%;
}

.international-events-carousel-video-ctn-carousel .css-1qzevvg {
  position: absolute !important;
  z-index: 2;
  bottom: 37px;
  left: calc(41.5vw - 40px);
  color: #fff;
  cursor: pointer;
}

.international-events-carousel-video-ctn-carousel .css-1fzpoyk {
	width: 650px;
	height: 550px !important;
}

.international-events-carousel-video-ctn-carousel-item-video {
	height: 100%;
	width: 100%;
}

.international-events-carousel-video-ctn-carousel-item {
	border-radius: 12px;
}