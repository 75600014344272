.experience-content-ctn {
  
}

.experience-content-ctn-description {
  margin-top: 0;
  padding: 0 32px;
  font-size: var(--txt-h5-sm);
  text-align: justify;
  line-height: 1.5;
}

@media screen and (min-width: 744px) {
  .experience-content-ctn-description {
    margin-top: 0px;
    padding: 0 32px;
    font-size: var(--txt-h5-md);
    text-align: justify;
  }
}

@media screen and (min-width: 1353px) {
  .experience-content-ctn-description {
    margin-top: 120px;
    padding: 0 32px;
    font-size: var(--txt-h5-xl);
    text-align: justify;
  }

  .experience-content-ctn-description p {
    text-align: justify;
  }
}