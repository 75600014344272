.magic-towns-slider-ctn {
	box-sizing: border-box;
	padding: 114px 0 64px 0;
  background-image: url('../../assets/img/header/Fondorosa.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
  overflow: hidden;
}

.magic-towns-slider-title {
	text-align: center;
	font-size: var(--txt-title-sm);
	font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
	color: var(--pink)!important;
}

.magic-towns-slider-ctn-buttons {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.magic-towns-slider-buttons {
  border: none;
  cursor: pointer;
  background: transparent;
}

.magic-towns-slider-buttons-img {
  width: 42px;
  height: 42px;
}

.magic-towns-slider-ctn-cards {
  display: flex;
  /* flex-wrap: wrap;*/ 
  box-sizing: border-box;
  gap: 24px;
  padding: 32px;
}

.magic-towns-slider-ctn-cards a {
  height: 100%;
  min-width: calc(90% - 120px);
}

.magic-towns-slider-ctn-card {
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 1s;
}
.magic-towns-slider-ctn-card-title{
  position: absolute;
  z-index: 1;
  padding: 20px;
  color: azure;
  font-weight: bold;
  
}

.magic-towns-slider-card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  aspect-ratio: 3/4;
}

.magic-towns-slider-ctn-card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Ajusta la altura del degradado */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  z-index: 1;
}

@media screen and (min-width: 744px) {
  .magic-towns-slider-ctn {
    padding: 144px 0 64px 0;
    background-size: 100% 90px;
  }
  
  .magic-towns-slider-title {
    font-size: var(--txt-title-md);
  }
  
  .magic-towns-slider-ctn-cards a {
    height: 100%;
    min-width: calc(52% - 92px);
  }
}

@media screen and (min-width: 1353px) {
  .magic-towns-slider-ctn {
    padding: 194px 0 64px 0;
    background-size: 100% 120px;
  }
  
  .magic-towns-slider-title {
    font-size: var(--txt-title-xl);
  }
  
  .magic-towns-slider-ctn-cards a {
    height: 100%;
    min-width: calc(24% - 20px);
  }
}
