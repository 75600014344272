.experience-images-ctn {
  padding: 50px 0;
  background-image: url('../../assets/img/header/Fondorosa.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
}

.experience-images-ctn-hero {
  background-image: url('../../assets/img/experiences/bg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top;
  display: block;
  padding: 32px;
}

.experience-images-ctn-hero-description {
  margin-left: 0;
}

.experience-images-img-experience-hero {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.experience-images-title {
  text-align: center;
  font-size: var(--txt-title-sm);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  color: var(--purple);
}

.experience-images-ctn-img {
  display: block;
  padding: 32px;
}

.experience-images-ctn-img-experience {
  position: relative;
  margin-bottom: 32px;
}

.experience-images-img-experience {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.experience-images-ctn-img-experience .experience-images-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-images-ctn-img-experience:hover .experience-images-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-images-ctn-img-experience-description {
  display: none;
}

.experience-images-img-experience-description {
  text-align: center;
  font-size: var(--txt-h5-xl);
  font-weight: 700;
  color: #fff;
}

@media screen and (min-width: 744px) {
  .experience-images-ctn {
    padding: 90px 0;
    background-image: url('../../assets/img/header/Fondorosa.webp');
    background-repeat: no-repeat;
    background-size: 100% 90px;
    background-position: top;
  }

  .experience-images-title {
    font-size: var(--txt-title-md);
  }

  .experience-images-ctn-img-experience .experience-images-ctn-img-experience-description {
    display: none;
  }

  .experience-images-ctn-img {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    padding: 32px;
  }

  .experience-images-ctn-img-experience {
    flex: 1 0 40%;
    position: relative;
  }
}

@media screen and (min-width: 1353px) {
  .experience-images-ctn {
    padding: 92px 0;
    background-image: url('../../assets/img/header/Fondorosa.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
  
  .experience-images-ctn-hero {
    background-image: url('../../assets/img/experiences/bg.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
  }
  
  .experience-images-ctn-hero-description {
    margin-left: 42px;
  }
  
  .experience-images-img-experience-hero {
    width: 40vw;
  }
  
  .experience-images-title {
    text-align: center;
    font-size: var(--txt-title-xl);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin-top: 42px;
    color: var(--purple);
  }
  
  .experience-images-ctn-img {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    padding: 32px;
  }
  
  .experience-images-ctn-img-experience {
    flex: 1 0 30%;
    position: relative;
  }
  
  .experience-images-img-experience {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  
  .experience-images-ctn-img-experience:hover .experience-images-ctn-img-experience-description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .experience-images-ctn-img-experience-description {
    display: none;
  }
  
  .experience-images-img-experience-description {
    text-align: center;
    font-size: var(--txt-h5-xl);
    font-weight: 700;
    color: #fff;
  }
}