.calendar2-known-ctn {
  background: whitesmoke;
  padding-top: 32px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.calendario2-franja{
  width: 100%;
  height: 50px;
  object-fit: cover;
}

/*=====EVENTOS=======*/
.calendar-events {
  margin: 0;
  padding: 0 32px;
}

.calendario2-hr{
  width: 50%;
  background: #eee;
}

.calendare-known-subtitle{
  font-size: var(--txt-h5-sm);
  color: var(--black);
}

.calendar2-know-ctn-cards{
  display: flex;
  box-sizing: border-box;
  gap: 24px;
  padding: 32px;
}

.calendar2-know-ctn-card{
  min-width: calc(80vw - 32px);
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 1s;
  background-color: blueviolet;
  min-height: 100%;
}

.calendar2-header{
  position: relative;
  height: 140px;
  width: 100%;
}
.calendar2-know-card-img{
  width: 100%;
  height: 100%;
  aspect-ratio: 19/6;
  object-fit: cover;
}
.calendar2-fecha{
  border: none;
  background: #eeb526f1;
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 5px;
}
.calendar2-know-ctn-card-description{
  height: 60%;
  background: white;
  padding: 15px 15px 30px 15px;
}
.calendar2-know-card-description-title{
  margin: 0;
  font-size: var(--txt-p-sm);
  font-weight: 700;
  color: var(--black);
  text-align: left;
  width: 100%;
  min-height: 47px;
}
.calendar2-know-card-description-text{
  font-size: var(--txt-p-sm);
  color: var(--gray);
  text-align: center;
  padding: 0;
  margin: 0 auto;
  display: flex;
}

.linkEvent{
  height: 400px;
  /*background-color: aqua;*/
}




/*=====CALENDARIO=======*/
.state-video-top-ctn{
  margin-top: 50px;
}
/*------------------------------nuevas------------------------------------*/



.calendaEvents-ctn-buttons {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarEvents-buttons {
  border: none;
  cursor: pointer;
  background: transparent;
}

.calendarEvents-buttons-img {
  width: 42px;
  height: 42px;
}


.calendar2-know-ctn-card:hover .calendar2-know-card-img{
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transition: 0.4s;
 
}

.titulo-calendario{
  margin-bottom: 0;
}


.calendario-title {
  font-size: var(--txt-title-sm);
  color: var(--purple)!important;
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  text-align: center;
}


/*.linkEvent{
  background-color: aqua;
}*/

.disabledStyle{
  pointer-events: none;
  text-decoration:none;
}



@media screen and (min-width: 744px) {
  .calendar2-known-ctn {
    padding-top: 32px;
  }

  .calendario-title {
    font-size: var(--txt-title-md);
  }

  .calendare-known-subtitle{
    font-size: var(--txt-h5-md);
  }

  .calendar2-know-ctn-card{
    min-width: calc(40vw - 32px);
  }

  

  .calendar2-know-card-description-title{
    font-size: var(--txt-p-md);
  }

  .calendar2-know-card-description-text{
    font-size: var(--txt-p-md);
  }
}

@media screen and (min-width: 1353px) {
  .calendar2-known-ctn {
    padding-top: 64px;
  }

  .calendario-title {
    font-size: var(--txt-title-xl);
  }

  .calendare-known-subtitle{
    font-size: var(--txt-h5-xl);
  }

  .calendar2-know-ctn-card{
    min-width: calc(20vw - 32px);
  }

  

  .calendar2-know-card-description-title{
    font-size: var(--txt-p-xl);
  }

  .calendar2-know-card-description-text{
    font-size: var(--txt-p-xl);
    text-align: justify;
    margin-top: 12px;
  }
}

/*body{
  background: #f1f1f1;
}
.calendario2-franja{
  width: 100%;
  height: 50px;
  object-fit: cover;
}

.calendario2-eventos{
  text-align: center;
}
.calendario2-hr{
  width: 50%;
  background: #eee;
}
.calendare-known-subtitle{
  font-size: var(--txt-h5-xl);
  color: var(--gray);
}

.calendar2-know-ctn-cards{
  display: flex;
  box-sizing: border-box;
  gap: 24px;
  padding: 32px;
}
.calendar2-know-ctn-card{
  min-width: calc(25% - 48px);
  max-width: 30%;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 1s;
  background-color: blueviolet;

}
.calendar2-header{
  position: relative;
  height:43%;
}
.calendar2-know-card-img{
  width: 100%;
  height: 100%;
}
.calendar2-fecha{
  border: none;
  background: #eeb526f1;
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 5px;
}
.calendar2-know-ctn-card-description{
  height: 57%;
  background: white;
  padding: 15px;
  max-height: 250px;
}
.calendar2-know-card-description-title{
  margin: 0;
  font-size: large;
  font-weight: 700;
  color: var(--black);
  width: 70%;
}
.calendar2-know-card-description-text{
  font-size: medium;
  color: var(--gray);
  text-align: justify;
}

.linkEvent{
  height: 400px;
  background-color: aqua;
}




.state-video-top-ctn{
  margin-top: 50px;
}



.calendaEvents-ctn-buttons {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarEvents-buttons {
  border: none;
  cursor: pointer;
  background: transparent;
}

.calendarEvents-buttons-img {
  width: 42px;
  height: 42px;
}


.calendar2-know-ctn-card:hover .calendar2-know-card-img{
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transition: 0.4s;
 
}

.titulo-calendario{
  margin-bottom: 0;
}


.calendario-title {
  font-size: var(--txt-title-xl);
  color: var(--purple);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 80px;
}


.linkEvent{
  background-color: aqua;
}

.disabledStyle{
  pointer-events: none;
  text-decoration:none;
}*/


/* Daniel Rivera: Agregue estas lneas qe vienen de estilos del home paar ue compartan el mismo patron de diseño */
.home-know-ctn-description {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-know-description-title {
  font-size: var(--txt-p-md);
  text-align: center;
}