.international-events-slider-video-ctn {
  padding: 50px 0 50px 0;
  background-image: url('../../assets/img/header/liston-azul.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
  overflow: hidden;
}

.international-events-slider-video-title {
  font-size: var(--txt-title-sm);
  color: var(--blue);
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
  text-align: center;
}
  
.international-events-slider-video-ctn-cards {
  display: flex;
  box-sizing: border-box;
  gap: 24px;
  padding: 32px;
}

.international-events-slider-video-ctn-card {
  min-width: calc(100vw - 64px);
  height: 310px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 1s;
}
.international-events-slider-video-ctn-card-titulo{
  
}

.international-events-slider-video-ctn-buttons {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.international-events-slider-video-buttons {
  border: none;
  cursor: pointer;
  background: transparent;
}

.international-events-slider-video-buttons-img {
  width: 42px;
  height: 42px;
}

@media screen and (min-width: 744px) {
  .international-events-slider-video-ctn {
    padding: 90px 0 90px 0;
    background-size: 100% 90px;
  }
  
  .international-events-slider-video-title {
    font-size: var(--txt-title-md);
  }
    
  .international-events-slider-video-ctn-cards {
    display: flex;
    box-sizing: border-box;
    gap: 24px;
    padding: 32px;
  }
  
  .international-events-slider-video-ctn-card {
    min-width: calc(40vw - 64px);
    height: 220px;
  }
  
}

@media screen and (min-width: 1353px) {
  .international-events-slider-video-ctn {
    padding: 120px 0 90px 0;
    background-size: 100% 120px;
  }
  
  .international-events-slider-video-title {
    font-size: var(--txt-title-xl);
  }
    
  .international-events-slider-video-ctn-cards {
    display: flex;
    box-sizing: border-box;
    gap: 24px;
    padding: 32px;
  }
  
  .international-events-slider-video-ctn-card {
    min-width: calc(30vw - 64px);
    height: 340px;
  }
  /*.international-events-slider-video-ctn {
    padding: 190px 0 90px 0;
    background-image: url('../../assets/img/header/liston-azul.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
  
  .international-events-slider-video-title {
    font-size: var(--txt-title-xl);
    color: var(--blue);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
    text-align: center;
  }
    
  .international-events-slider-video-ctn-cards {
    display: flex;
    box-sizing: border-box;
    gap: 24px;
    padding: 32px;
  }
  
  .international-events-slider-video-ctn-card {
    min-width: calc(33% - 48px);
    height: 310px;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: all 1s;
  }
  .international-events-slider-video-ctn-card-titulo{
    
  }
  
  .international-events-slider-video-ctn-buttons {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .international-events-slider-video-buttons {
    border: none;
    cursor: pointer;
    background: transparent;
  }
  
  .international-events-slider-video-buttons-img {
    width: 42px;
    height: 42px;
  }*/
}
