.hero-slider-container-descargas {
  width: 100%;
  height: 720px; /* Para que ocupe toda la altura de la pantalla */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.background-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2; /* La imagen está en el fondo */
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra todo el contenedor */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Asegúrate de que cubra toda la altura del contenedor */
  background-color: rgba(0, 0, 0, 0.5); /* Un overlay semi-transparente */
  z-index: 0; /* Asegura que el overlay esté sobre la imagen pero debajo del contenido */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem; /* Añadir padding para el overlay en pantallas pequeñas */
  box-sizing: border-box; /* Asegura que el padding no afecte el tamaño total */
}

.content {
  text-align: center;
  color: white;
  z-index: 1; /* Asegúrate de que el contenido esté sobre el overlay */
  padding: 0 1rem; /* Añadir padding para el contenido en pantallas pequeñas */
  box-sizing: border-box; /* Asegura que el padding no afecte el tamaño total */
}

.content h1 {
  margin-bottom: 1rem;
}

.content p {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}



.content input {
  height: 30px;
  min-width: 400px; /* Reducir el ancho mínimo en pantallas pequeñas */
  padding: 0.5rem 1rem;
  font-size: 1rem; /* Tamaño por defecto */
  border: none;
  border-radius: 8px;
  z-index: 2; /* Asegúrate de que el input esté sobre el overlay */
  position: relative; /* Necesario para que el z-index funcione */
}

@media (max-width: 1200px) {
  .content h1 {
    font-size: 2.5rem; /* Ajuste para pantallas medianas */
  }

  .content p {
    font-size: 1.3rem;
  }

  .content input {
    min-width: 400px; /* Ajuste para pantallas medianas */
  }
}

@media (max-width: 992px) {
  .content h1 {
    font-size: 2rem; /* Ajuste para pantallas grandes de tabletas */
  }

  .content p {
    font-size: 1.2rem;
  }

  .content input {
    min-width: 350px; /* Ajuste para pantallas grandes de tabletas */
  }
}

@media (max-width: 768px) {
  .hero-slider-container-descargas {
    height: 500px; /* Ajustar la altura en pantallas pequeñas */
  }

  .content h1 {
    font-size: 1.8rem; /* Ajuste para pantallas pequeñas */
  }

  .content p {
    font-size: 1.1rem;
  }

  .content input {
    min-width: 90%; /* Asegura que el input ocupe todo el ancho disponible */
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .content h1 {
    font-size: 1.5rem; /* Ajuste para pantallas muy pequeñas */
  }

  .content p {
    font-size: 1rem;
  }

  .content input {
    font-size: 0.8rem; /* Ajuste para pantallas muy pequeñas */
  }
}




.hero-slider-container-descargas {
  position: relative;
}

.results-container {
  position: absolute;
    top: 74%;
    right: 0;
    left: 0;
    border-radius: 3px;
    background-color: #fff;
  max-width: 430px;
  margin: 0 auto;
  z-index: 3;
}
.results-container ul {
  text-align: left;
}
.results-container ul li {
  color: black;
  list-style: none;
}