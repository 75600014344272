.acapulco_reel_containerClasificacion {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.acapulco_reel_titleClasificacion {
  text-align: center;
  color: var(--blue)!important;
}

.acapulco_reel_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.acapulco_reel_containerClasificacion .acapulco_reel_column {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.acapulco_reel_column img.acapulco_reel_icon-img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.acapulco_reel_colum p.text-center {
text-align: center!important;
}

