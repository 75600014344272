.barrios-header-ctn {
	box-sizing: border-box;
	padding: 114px 0 64px 0;
	background-image: url('../../assets/img/header/Fondorosa.webp');
	background-repeat: no-repeat;
	background-size: 100% 50px;
	background-position: top;
	overflow: hidden;
}

.barrios-header-title {
	font-size: var(--txt-title-sm);
	color: var(--pink);
	font-family: "Bebas Neue", sans-serif;
	letter-spacing: 1px;
	text-align: center;
	margin: 0;
}

.barrios-header-ctn p {
	margin: 0 auto;
	max-width: 80%;
	text-align: center;
}