.destination-description-ctn {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 42px;
}

.destination-description-desc-ctn {
  padding: 0 32px;
  flex: 1 0 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 32px;
}

.destination-description-desc-ctn-map {
  padding: 0 32px;
  flex: 1 0 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 32px;
  height: 100vh;
}

.destination-description-desc-text-title {
  text-align: center;
  font-size: var(--txt-title-sm);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 62px;
  color: var(--pink);
}

.destination-description-desc-text-desc {
  /*list-style: none;*/
}

.destination-description-desc-text-desc-li {
  font-size: var(--txt-h5-sm);
  margin: 12px 0;
  text-align: left;
  line-height: 1.5;
}

@media screen and (min-width: 744px) {
  
  .destination-description-desc-text-title {
    font-size: var(--txt-title-md);
  }
  
  .destination-description-desc-text-desc-li {
    font-size: var(--txt-h5-md);
  }
}

@media screen and (min-width: 1353px) {
  .destination-description-ctn {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 42px;
  }
  
  .destination-description-desc-ctn {
    margin-top: 120px;
    padding: 0 42px;
    flex: 1 0 40%;
    min-height: 100vh;
  }
  
  .destination-description-desc-text-title {
    text-align: center;
    font-size: var(--txt-title-xl);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin-bottom: 62px;
    color: var(--pink);
  }
  
  .destination-description-desc-text-desc {
    /*list-style: none;*/
  }
  
  .destination-description-desc-text-desc-li {
    font-size: var(--txt-h5-xl);
    margin: 18px 0;
    text-align: justify;
  }
}
