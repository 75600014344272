.experience-images-sustanaible-ctn {
  padding: 50px 0 0 0;
  background-image: url('../../assets/img/header/Fondorosa.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.experience-images-sustanaible-ctn-hero {
  background-image: url('../../assets/img/experiences/bg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top;
  display: block;
  padding: 32px;
}

.experience-images-sustanaible-ctn-hero-description {
  display: block;
}

.experience-images-sustanaible-img-experience-hero {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.experience-images-sustanaible-title {
  text-transform: uppercase!important;
  text-align: center;
  font-size: var(--txt-title-sm);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  margin-top: 62px;
  color: var(--purple);
}

.experience-images-sustanaible-ctn-img {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}

.experience-images-sustanaible-ctn-img-experience-0, 
.experience-images-sustanaible-ctn-img-experience-1, 
.experience-images-sustanaible-ctn-img-experience-2,
.experience-images-sustanaible-ctn-img-experience-3,
.experience-images-sustanaible-ctn-img-experience-4 {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  margin-bottom: 32px;
}

.experience-images-sustanaible-img-experience {
  width: 100%;
  height: 100%;
  aspect-ratio: auto;
  object-fit: cover;
}

.experience-images-sustanaible-ctn-img-experience-0 .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-1 .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-2 .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-3 .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-4 .experience-images-sustanaible-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-images-sustanaible-ctn-img-experience-0:hover .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-1:hover .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-2:hover .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-3:hover .experience-images-sustanaible-ctn-img-experience-description,
.experience-images-sustanaible-ctn-img-experience-4:hover .experience-images-sustanaible-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-images-sustanaible-ctn-img-experience-description {
  display: none;
}

.experience-images-sustanaible-img-experience-description {
  text-transform: uppercase;
  text-align: center;
  font-size: var(--txt-h5-xl);
  font-weight: 700;
  color: #fff;
}

@media screen and (min-width: 744px) {
  .experience-images-sustanaible-ctn {
    padding: 90px 0 0 0;
    background-size: 100% 90px;
  }
  
  .experience-images-sustanaible-ctn-hero-description {
    display: block;
  }
  
  .experience-images-sustanaible-img-experience-hero {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
  }
  
  .experience-images-sustanaible-title {
    font-size: var(--txt-title-md);
  }
  
  .experience-images-sustanaible-ctn-img {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
  
  .experience-images-sustanaible-ctn-img-experience-0, 
  .experience-images-sustanaible-ctn-img-experience-1, 
  .experience-images-sustanaible-ctn-img-experience-2,
  .experience-images-sustanaible-ctn-img-experience-3,
  .experience-images-sustanaible-ctn-img-experience-4 {
    flex: 1 0 40%;
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    margin-bottom: 32px;
  }
  
  .experience-images-sustanaible-img-experience {
    width: 100%;
    height: 100%;
    aspect-ratio: auto;
    object-fit: cover;
  }
  
  .experience-images-sustanaible-ctn-img-experience-0 .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-1 .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-2 .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-3 .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-4 .experience-images-sustanaible-ctn-img-experience-description {
    display: none;
  }
  
  .experience-images-sustanaible-ctn-img-experience-description {
    display: none;
  }
}

@media screen and (min-width: 1353px) {
  .experience-images-sustanaible-ctn {
    padding: 120px 0;
    background-size: 100% 120px;
  }

  .experience-images-sustanaible-ctn-hero {
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .experience-images-sustanaible-ctn-hero-description {
    margin-left: 42px;
  }

  .experience-images-sustanaible-img-experience-hero {
    width: 40vw;
  }

  .experience-images-sustanaible-title {
    text-transform: uppercase!important;
    text-align: center;
    font-size: var(--txt-title-xl);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin-top: 42px;
    color: var(--purple);
  }

  .experience-images-sustanaible-ctn-img {
    display: grid;
    grid-template-areas: 
    "a b b c"
    "d b b e";
    padding: 32px;
    height: 100%;
    gap: 32px;
  }

  .experience-images-sustanaible-ctn-img-experience-0 {
    grid-area: a;
    position: relative;
    height: calc(470px / 2);
  }

  .experience-images-sustanaible-ctn-img-experience-1 {
    grid-area: b;
    position: relative;
    height: calc(470px + 32px);
  }

  .experience-images-sustanaible-ctn-img-experience-2 {
    grid-area: c;
    position: relative;
    height: calc(470px / 2);
  }

  .experience-images-sustanaible-ctn-img-experience-3 {
    grid-area: d;
    position: relative;
    height: calc(470px / 2);
  }

  .experience-images-sustanaible-ctn-img-experience-4 {
    grid-area: e;
    position: relative;
    height: calc(470px / 2);
  }

  .experience-images-sustanaible-img-experience {
    width: 100%;
    height: 100%;
    aspect-ratio: auto;
    object-fit: cover;
  }

  .experience-images-sustanaible-ctn-img-experience-0:hover .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-1:hover .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-2:hover .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-3:hover .experience-images-sustanaible-ctn-img-experience-description,
  .experience-images-sustanaible-ctn-img-experience-4:hover .experience-images-sustanaible-ctn-img-experience-description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .experience-images-sustanaible-ctn-img-experience-description {
    display: none;
  }
}