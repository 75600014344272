.train-maya-video-top-ctn {
  height: 260px;
  width: 100%;
  aspect-ratio: 9/6;
  object-fit: cover
}

.train-maya-img-ribbon {
  width: 100%;
  height: 50px;
  object-fit: cover;
  margin-left: -32px;
}

/*.trenMaya-images-ctn {
  background-image: url('../../assets/img/header/liston-azul.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  min-height: 458px;
}

.trenMaya-images-ctn-hero {
  background-image: url('../../assets/img/trenMaya/estacion.webp');
  background-repeat: no-repeat;
  background-size: 99.8% auto;
  background-position: top;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 92px 0;
  
}

.trenMaya-images-ctn-hero-description {
  margin-left: 42px;
}

.trenMaya-images-img-experience-hero {
  width: 40vw;
}

.trenMaya-images-title {
  text-align: left;
  font-size: var(--txt-title-xl);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  margin-top: 42px ;
  margin-left: 50px;
  color: var(--white);
}

.trenMaya-images-ctn-img {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 32px;
}

.trenMaya-images-ctn-img-experience {
  flex: 1 0 30%;
  position: relative;
}

.trenMaya-images-img-experience {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.trenMaya-images-ctn-img-experience:hover .trenMaya-images-ctn-img-experience-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trenMaya-images-ctn-img-experience-description {
  display: none;
}

.trenMaya-images-img-experience-description {
  text-align: center;
  font-size: var(--txt-h5-xl);
  font-weight: 700;
  color: #fff;
}*/

@media screen and (min-width: 744px) {
  .train-maya-video-top-ctn {
    height: 530px;
    width: 100%;
    aspect-ratio: 9/6;
    object-fit: cover
  }
}

@media screen and (min-width: 1353px) {
  .train-maya-video-top-ctn {
    height: 1090px;
    width: 100%;
    aspect-ratio: 9/6;
    object-fit: cover;
  }
}