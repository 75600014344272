.muertos-footer-container {
    width: 100%;
    height: 100%; /* Altura deseada */
    overflow: hidden; /* Para asegurar que la imagen no se desborde */
  }
  
  .muertos-footer-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Para asegurar que la imagen cubra completamente el contenedor */
  }