.hero-slider-container {
    max-width: 100%;
    max-height: 720px;
  }
  
  .hero-slide {
    position: relative;
    width: 100%;
  }
  
  .hero-slide-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .hero-slider-container .carousel .slide img {
    border-radius: 0px;
    height: 720px;
  }

  .slide-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Fondo semitransparente negro que cubre toda la imagen */
  }
  
  .slide-content {
    position: absolute;
    top: 50%; /* Centrar verticalmente */
    left: 50%; /* Centrar horizontalmente */
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px; /* Mantén los márgenes internos */
  }
.slide-overlay h1 {
  color: #fff;
}

/* Estilo del botón "Ver más" */
.slide-overlay .ver-mas-button {
  width: 200px;
  background-color: var(--pink);
  color: white;
  padding: 10px 20px;
  border-radius: 9px; /* Bordes redondeados */
  border: none;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
}
  
  @media screen and (max-width: 767px) {
    .slide-content {
      position: absolute;
      top: 50%; /* Centrar verticalmente */
      left: 40%; /* Centrar horizontalmente */
      transform: translate(-30%, -50%);
      text-align: center;
      padding: 0px; /* Mantén los márgenes internos */
    }
    .hero-slide {
      max-height: 320px; /* Altura relativa */
    }
  
    .hero-slider-container .carousel .slide img {
      height: 330px; /* Altura relativa */
    }

     /* Ajuste del tamaño de la fuente para pantallas pequeñas */
     .slide-overlay h1 {
      font-size: 22px; /* Ajusta el tamaño según prefieras */
    }

    .slide-overlay .ver-mas-button {
      font-size: 14px; /* Reduce el tamaño de la fuente del botón */
    }
  }
  