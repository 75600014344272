.home-events-ctn {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  margin-top: 0px;
  padding: 20px 0;
  background-color: azure;
}
.home-events {
  background: whitesmoke;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.home-the-most-viewed-title {
  font-size: var(--txt-title-sm);
  text-align: center;
  margin: 20px  0 0 0;
  /* color: var(--purple); */
  letter-spacing: 1px;
}

.home-events-sections {
  display: block;
  padding: 24px 32px;
  /* height: 100%; */
}

.home-events-sections-card-0,
.home-events-sections-card-1,
.home-events-sections-card-2,
.home-events-sections-card-3,
.home-events-sections-card-4 {
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  /* height: 287px; */
  margin-bottom: 0px;
}

.home-events-sections-card-0:hover .home-the-most-viewed-sections-card-img,
.home-events-sections-card-1:hover .home-the-most-viewed-sections-card-img,
.home-events-sections-card-2:hover .home-the-most-viewed-sections-card-img,
.home-events-sections-card-3:hover .home-the-most-viewed-sections-card-img,
.home-events-sections-card-4:hover .home-the-most-viewed-sections-card-img {
  transform: scale(1.2);
  transition: 0.4s;
}

.home-events-card-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.home-events-sections-card-hover {
  display: none;
}

.home-the-most-viewed-sections-card-hover-title {
  color: gray;
  font-weight: 700;
  font-size: var(--txt-h2-xl);
  text-align: center;
}

.home-events-sections-card-0 .home-events-sections-card-hover,
.home-events-sections-card-1 .home-events-sections-card-hover,
.home-events-sections-card-2 .home-events-sections-card-hover,
.home-events-sections-card-3 .home-events-sections-card-hover,
.home-events-sections-card-4 .home-events-sections-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  /* background: rgba(0, 0, 0, .3); */
  width: 50%;
  /* height: 100%; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-events-sections-card-0:hover .home-events-sections-card-hover,
.home-events-sections-card-1:hover .home-events-sections-card-hover,
.home-events-sections-card-2:hover .home-events-sections-card-hover,
.home-events-sections-card-3:hover .home-events-sections-card-hover,
.home-events-sections-card-4:hover .home-events-sections-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  /* background: rgba(0, 0, 0, .5); */
  width: 50%;
  /* height: 100%; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 744px) {  
  .home-the-most-viewed-title {
    font-size: var(--txt-title-md);
  }
  
  .home-events-sections {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
      "a b"
      "c d";
    gap: 20px;
    padding: 32px 32px 0 32px;
    background-position: top;
    height: auto;
  }
  
  .home-events-sections-card-0 {
    grid-area: a;
  }
  
  .home-events-sections-card-1{
    grid-area: b;
  }
  
  .home-events-sections-card-2{
    grid-area: c;
  }
  
  .home-events-sections-card-3{
    grid-area: d;
  }
  
  .home-events-sections-card-4 {
    grid-area: e;
  }
  
  .home-events-sections-card-0,
  .home-events-sections-card-1,
  .home-events-sections-card-2,
  .home-events-sections-card-3,
  .home-events-sections-card-4 {
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    height: auto;
  }
  
  .home-events-sections-card-0:hover .home-the-most-viewed-sections-card-img,
  .home-events-sections-card-1:hover .home-the-most-viewed-sections-card-img,
  .home-events-sections-card-2:hover .home-the-most-viewed-sections-card-img,
  .home-events-sections-card-3:hover .home-the-most-viewed-sections-card-img,
  .home-events-sections-card-4:hover .home-the-most-viewed-sections-card-img {
    transform: scale(1.05);
    transition: 0.5s;
  }

  .home-events-sections-card-0 .home-events-sections-card-hover,
  .home-events-sections-card-1 .home-events-sections-card-hover,
  .home-events-sections-card-2 .home-events-sections-card-hover,
  .home-events-sections-card-3 .home-events-sections-card-hover,
  .home-events-sections-card-4 .home-events-sections-card-hover {
    display: none;
  }
}
.home-events-subtitle {
  font-size: var(--txt-title-sm);
  text-align: left;
  margin: 0;
  margin-left: 50px;
  color: var(--green);
  font-family: "Gill Sans", sans-serif;;
  letter-spacing: 1px;
}

@media screen and (min-width: 1353px) {  
  .home-the-most-viewed-title {
    font-size: var(--txt-title-xl);
  }
  
  .home-events-sections {
    padding: 32px 32px 0 32px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .home-events-ctn {
    grid-template-columns: 1fr; /* Una sola columna en pantallas móviles */
  }
}
