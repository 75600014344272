.international-events-explore-ctn {
	margin: 00 0 0 0;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 92px 0;
	background-image: url('../../assets/img/header/Fondoamarrillo.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
}

.international-events-explore-ctn-travelers {
	background: var(--yellow);
	padding: 64px 0;
}

.international-events-explore-title {
	text-align: center;
	font-size: var(--txt-title-xl);
	font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
	color: #fff;
	margin: 0;
}

.international-events-explore-subtitle {
	text-align: center;
	margin: 0;
	font-size: var(--txt-h5-xl);
	color: #fff;
}

.international-events-explore-ctn-travels {
	margin-top: 64px;
	display: flex;
	flex-wrap: wrap;
	gap: 3%;
	justify-content: center;
	align-items: center;
	padding: 0 64px;
}

.international-events-explore-ctn-travel {
	flex: 1 0 20%;
	height: 570px;
	padding-bottom: 64px;
}

.international-events-explore-travel {
	aspect-ratio: 3/4;
	width: 100%;
	height: 100%;
	border-radius: 12px;
}

.international-events-explore-description {
	text-align: center;
	font-size: var(--txt-h5-xl);
	color: #fff;
	font-weight: 700;
}