/* Estilos para el contenedor principal */
.category-container {
    margin: 0 auto;          /* Centra el contenedor horizontalmente */
    padding: 0 20px;         /* Espaciado a los lados del contenedor */
    max-width: 1200px;       /* Ancho máximo del contenedor */
  }
  
  /* Estilos para el título principal */
  .category-container h1 {
    font-size: 45px;
    color: var(--pink);             /* Ajusta esto según tu archivo de variables CSS */
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;      /* Convierte el texto a mayúsculas */
    margin-bottom: 20px;            /* Espaciado inferior */
  }
  
  /* Estilos para cada artículo */
  .post-item {
    margin-bottom: 20px;     /* Espaciado inferior entre artículos */
  }
  
  /* Estilos para el título de cada artículo */
  .post-item h2 {
    margin-bottom: 10px;     /* Espaciado inferior */
  }
  
  /* Estilos para las imágenes de los artículos */
  .post-item img {
    max-width: 100%;         /* Ajusta la imagen al ancho del contenedor */
    height: auto;            /* Mantiene la proporción de la imagen */
    display: block;          /* Muestra la imagen como un bloque */
    margin: 0 auto;          /* Centra la imagen horizontalmente */
  }

  /* Otros estilos que ya tienes... */

.no-articles {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    color: #666; /* Puedes ajustar el color según tus necesidades */
  }

  .back-to-blog-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .back-to-blog-link {
    padding: 10px 20px; /* Espaciado interno */
    background-color: var(--pink); /* Color de fondo */
    color: #fff; /* Color del texto */
    text-decoration: none; /* Quitar subrayado del enlace */
    border-radius: 5px; /* Bordes redondeados */
    text-align: center; /* Alinear texto al centro */
    transition: background-color 0.3s ease; /* Transición suave del color de fondo */
  }
  
  