.muertos_mapa_container {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
  background-color: #56004c;
}
.muertos_mapa_row {
  text-align: center;
  
  
}

.muertos_mapa_row svg {
  width: 3000px!important;
}




.muertos_mapa_container {
  position: relative;
}

.muertos_mapa_row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #56004c;
  padding: 0;
  margin: 0;
}

.info-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  max-width: 300px;
  z-index: 10;
}

.info-card h3 {
  font-size: 18px;
  margin: 0 0 8px 0;
}

.info-card p {
  font-size: 14px;
  margin: 0;
}

.close-button {
  margin-top: 8px;
  padding: 8px 16px;
  background-color: #f8e000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #d3ba00;
}
