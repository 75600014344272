.muertos-header-ctn {
	box-sizing: border-box;
	padding: 114px 0 30px 0;
}

.muertos-container-ciudades-info {
  margin-top: 30px;
  margin-bottom: 30px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.container-ciudades-patrimonio.reveal-scroll.visible {
  opacity: 1;
}

.ciudades-left-column {
  margin-top: 30px;
  margin-bottom: 40px;
  flex: 1;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar horizontalmente */
}



.lazy-loaded-image {
  max-width: 40%; /* Hacer la imagen más pequeña */
  height: auto; /* Mantener la responsividad */
  margin: 0 auto; /* Centrar horizontalmente */
}

.ciudades-subtitle {
  font-size: 28px;
  margin-bottom: 10px;
}

.ciudades-paragraph {
  font-size: 20px;
  line-height: 1.5;
  text-align: justify;
}

.ciudades-strong-p {
  font-weight: bold;
}

.ciudades-quote {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.quote-container {
  margin: auto;
  max-width: 600px;
}

.ciudades-link {
  text-decoration: none;
  color: var(--black);
}

@media screen and (max-width: 768px) {
  .muertos-container-ciudades-info {
      flex-direction: column;
      align-items: center;
  }

  .ciudades-left-column {
      max-width: 100%;
      padding-right: 20px;
      padding-left: 20px;
  }
}

.muertos-mb-10{
  margin-bottom: 10px;
}
.muertos-mb-15{
  margin-bottom: 15px;
}
.muertos-mb-20{
  margin-bottom: 20px;
}