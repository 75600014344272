.muertos-header-ctn {
	box-sizing: border-box;
	padding: 64px 0 64px 0;
	
}

.muertos-header-title {
	font-size: var(--txt-title-sm);
	color: var(--pink);
	font-family: "Bebas Neue", sans-serif;
	letter-spacing: 1px;
	text-align: center;
	margin: 0;
}

.muertos-header-ctn p {
	margin: 0 auto;
	max-width: 80%;
	text-align: center;
	color: #fff;
}