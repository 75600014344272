.acapulco_reel_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    gap: 20px;
  }
  
  .acapulco_reel_video {
    flex: 1;
    width: 100%;
    max-width: 600px;
    height: auto;
    aspect-ratio: 9 / 16; /* Reduce la altura del video */
  }
  
  .acapulco_reel_content {
    flex: 1;
    max-width: 600px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .acapulco_reel_content h2 {
    color: var(--blue)!important;
  }
  
  .acapulco_reel_content p {
    font-size: var(--txt-h5-sm);
    text-align: center !important;
    line-height: 1.5;
    margin: 0 auto 32px auto;
  }
  
  .acapulco_reel_content a {
    color: #038ca0;
    text-decoration: none;
  }
  
  .acapulco_reel_content a:hover {
    text-decoration: underline;
  }
  .acapulco_reel_image {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    margin: 20px auto; /* Agrega margen superior e inferior y centra horizontalmente */
    width: 100%; /* Asegúrate de que ocupe todo el ancho del contenedor */
  }
  
  .acapulco_reel_img {
    max-width: 80%; /* Ajusta el tamaño máximo de la imagen */
    height: auto; /* Mantén la proporción de la imagen */
    transition: transform 0.3s ease; /* Agrega una transición suave para hover */
    border-radius: 15px; /* Bordes redondeados */ 
  }
  
  .acapulco_reel_img:hover {
    transform: scale(1.05); /* Efecto de zoom al pasar el mouse */
  }
  /* Responsivo para pantallas pequeñas */
  @media (max-width: 768px) {
    .acapulco_reel_container {
      flex-direction: column;
      align-items: center;
    }
  
    .acapulco_reel_video,
    .acapulco_reel_content {
      max-width: 100%;
    }
  }