.childrens-culture-tourism-gallery-ctn {
    width: 80%;
    /* max-width: 900px; */
    margin: 20px auto;
    text-align: center;
  }
  
  .gallery-title {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .video-item {
    width: 100%;
    aspect-ratio: 16 / 9; /* Mantiene la proporción del video */
  }
  
  .video-item iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
  }
  .childrens-culture-tourism-gallery-img {
    width: 50%;
    height: auto;
    border: none;
    border-radius: 8px;
  }
  /* Responsivo: En pantallas pequeñas, 1 columna */
  @media (max-width: 768px) {
    .video-grid {
      grid-template-columns: repeat(1, 1fr);
    }
    .childrens-culture-tourism-gallery-img {
      width: 100%;
      height: auto;
      border: none;
      border-radius: 8px;
    }
  }
  