.footer-ctn {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.footer-image-item {
  display: flex;
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center;     /* Centra verticalmente el contenido */
}

.footer-image {
  max-width: 200px;
  height: auto;
}

.footer-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: none; /* Color de fondo del botón */
  color: #fff;
  text-decoration: none;
  border: solid 1px #02a0af;
  border-radius: 5px;
  font-size: var(--txt-p-xl);
}

.footer-button:hover {
  background-color: #007278; /* Color al hacer hover */
}

.footer-button-icon {
  margin-left: 8px; /* Espacio entre el texto y la imagen */
  width: 40px;      /* Ajusta el tamaño del icono según el diseño */
  height: auto;
}

.footer-ctn-contact {
  padding: 5px;
  background: var(--black);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Alinea al tope */
}

.footer-contact-logo {
  width: 260px;
  height: 80px;
  aspect-ratio: 19/6;
  object-fit: contain;
}

.footer-ctn-contact-section {
  flex: 1 0 100%; /* Full width for mobile */
  margin: 0 auto 42px 0;
  align-items: flex-start; /* Alinea al tope */
}

.footer-ctn-contact-section.logo-container {
  align-self: center; /* Centra el contenedor del logo */
}

.footer-ctn-contact-section > a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.footer-ctn-contact-section > div > div {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinea al tope */
}

.footer-contact-section-text {
  color: #fff;
  font-size: var(--txt-p-xl);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-contact-section-title {
  color: #fff;
  font-size: var(--txt-h5-xl);
  font-weight: 700;
  text-align: center;
  margin: 24px auto;
}

.footer-contact-section-ul {
  list-style: none;
  padding: 0;
}

.footer-contact-section-ul li {
  margin-bottom: 12px;
}

.footer-ctn-contact-legal {
  background: black;
  display: block;
  height: 50%;
  padding: 10px;
}

.footer-ctn-contact-legal-links {
  display: block;
}

.footer-ctn-contact-legal-links p {
  text-align: center;
  font-size: var(--txt-p-md);
  margin: 0 auto;
  margin-top: 0px;
}

.footer-contact-legal-link {
  font-size: var(--txt-h5-md);
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
}

.footer-contact-rss {
  height: 36px;
  width: 36px;
}

.footer-logos {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dos columnas en pantallas grandes */
  margin-top: 0px;
  padding: 20px 0;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  gap: 20px;
}
.footer-logos > * {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
}
@media (max-width: 768px) {
  .footer-logos {
    grid-template-columns: 1fr; /* Una sola columna en pantallas móviles */
  }
}

@media screen and (min-width: 744px) and (max-width: 1024px) {
  .footer-ctn-contact-section{
    flex: 1 0 45%; /* 2 columns for iPad Mini */
    margin: 0 auto 42px 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1352px) {
  .footer-ctn-contact {
    flex-wrap: nowrap;
  }

  .footer-contact-logo {
    width: 220px;
    height: 60px;
  }

  .footer-ctn-contact-section {
    flex: 1 0 22%; /* 4 columns */
    margin: 0;
  }

  .footer-ctn-contact-legal {
    height: 50px;
    padding-top: 0;
  }

  .footer-ctn-contact-legal-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1353px) {
  .footer-ctn-contact-section {
    flex: 1 0 22%; /* 4 columns */
  }
}
.footer-separator {
  width: 2px; 
  height: 50px; 
  background-color: #ccc; 
  margin: 20px 20px 0 20px; /* Espacio entre el separador y los elementos */
}