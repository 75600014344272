.tren-maya-ctn {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 64px 32px 32px 32px;
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
}

.informacion {
  display: flex;
  flex-direction: column; /* Alinear elementos uno sobre otro */
  padding: 10;
  align-items: center;
}

.informacion--left {
  width: 100%;
}

.train-maya-img-ribbon {
  width: 100vw;
  height: 50px;
  object-fit: cover;
  margin-left: -32px;
}

.informacion--titulo {
  text-align: center;
  color: var(--yellow);
  font-size: 45px;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
}

.informacion--texto {
  font-size: var(--txt-h5-sm);
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  line-height: 1.5;
}

.informacion--texto p {
  text-align: center;
  width: 100%;
}

.informacion--right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.informacion--imagen {
  width: 100%;
  height: 100%;
  margin: 20px;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.galeria {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.imagen1 {
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  aspect-ratio: 4/3;
  margin-bottom: 12px;
}
.imagen2 {
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  aspect-ratio: 4/3;
  margin-bottom: 12px;
}
.imagen3 {
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  aspect-ratio: 4/3;
  margin-bottom: 12px;
}
.galeria--contenido {
  width: 100%;
  display: block;
}

.galeria--titulo {
  text-align: center;
  color: var(--purple);
  font-size: var(--txt-title-sm);
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
}
.galeria img {
  width: 100%;
  height: 100%;
}

.videos {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.videos--titulo {
  text-align: center;
  color: var(--blue);
  font-size: var(--txt-title-xl);
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 1px;
}

@media screen and (min-width: 744px) {
  .tren-maya-ctn {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 64px 32px 32px 32px;
    background-repeat: no-repeat;
    background-size: 100% 50px;
    background-position: top;
  }

  .informacion {
    display: block;
    padding: 0;
    align-items: center;
  }

  .informacion--left {
    width: 100%;
  }

  .informacion--left p {
    text-align: center;
  }

  .train-maya-img-ribbon {
    width: 100vw;
    height: 50px;
    object-fit: cover;
    margin-left: -32px;
  }

  .informacion--titulo {
    color: var(--yellow);
    font-size: 45px;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
  }

  .informacion--texto {
    font-size: var(--txt-h5-md);
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: justify;
    line-height: 1.5;
  }

  .informacion--texto p {
    text-align: center;
    width: 100%;
  }

  .informacion--right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .informacion--imagen {
    width: 100%;
    height: 100%;
    margin: 20px;
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  .galeria {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .imagen1 {
    grid-area: imagen1;
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    aspect-ratio: 4/3;
    margin-bottom: 12px;
  }
  .imagen2 {
    grid-area: imagen2;
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    aspect-ratio: 4/3;
    margin-bottom: 12px;
  }
  .imagen3 {
    grid-area: imagen3;
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    aspect-ratio: 4/3;
    margin-bottom: 12px;
  }
  .imagen3 {
    grid-area: imagen3;
    object-fit: cover;
    border-radius: 10px;
  }
  .galeria--contenido {
    width: 95%;
    padding: 10px;
    column-gap: 10px;
    row-gap: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 250px 250px;
    grid-template-areas:
      "imagen1 imagen1 imagen2"
      "imagen1 imagen1 imagen3";
  }
  .galeria--titulo {
    text-align: center;
    color: var(--purple);
    font-size: var(--txt-title-sm);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
  }
  .galeria img {
    width: 100%;
    height: 100%;
  }

  .videos {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .videos--titulo {
    text-align: center;
    color: var(--blue);
    font-size: var(--txt-title-xl);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
  }
}

@media screen and (min-width: 1353px) {
  .tren-maya-ctn {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 180px 32px 32px 32px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
  .informacion {
    display: flex;
    padding: 40px;
    align-items: start;
  }
  .informacion--left {
    width: 100%;
  }
  .informacion--titulo {
    color: var(--yellow);
    font-size: 45px;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
  }
  .informacion--texto {
    font-size: var(--txt-h5-xl);
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: justify;
  }
  .informacion--right {
    width: 100%;
    margin: 20px;
  }
  .informacion--imagen {
    width: 100%;
    margin: 20px;
  }

  .galeria {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .imagen1 {
    grid-area: imagen1;
    aspect-ratio: auto;
  }
  .imagen2 {
    grid-area: imagen2;
    aspect-ratio: auto;
  }
  .imagen3 {
    grid-area: imagen3;
    aspect-ratio: auto;
  }
  .galeria--contenido {
    width: 95%;
    padding: 10px;
    column-gap: 10px;
    row-gap: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 250px 250px;
    grid-template-areas:
      "imagen1 imagen1 imagen2"
      "imagen1 imagen1 imagen3";
  }
  .galeria--titulo {
    text-align: center;
    color: var(--purple);
    font-size: var(--txt-title-xl);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
  }
  .galeria img {
    width: 100%;
    height: 100%;
  }

  .videos {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .videos--titulo {
    text-align: center;
    color: var(--blue);
    font-size: var(--txt-title-xl);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
  }

  .magic-towns-video-slider-ctn-carousel {
    flex-direction: column !important;
  }

  .magic-towns-video-slider-ctn-carousel .css-1qzevvg {
    position: relative !important;
    left: 0 !important;
  }
}
