.scroll-to-top-ctn {
	position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 99;
  display: flex;
  animation-duration: .5s;
  animation-name: btn-to-top-in;
	height: 54px;
	width: 54px;
	border-radius: 9px;
	box-shadow: 0 3px 3px 1px rgba(0, 0, 0, .2);
}

.scroll-to-top-btn-img {
	width: 100%;
	height: 100%;
	background: var(--green);
	border: none;
	border-radius: 9px;
	cursor: pointer;
}

.hide {
	display: none;
}


@keyframes btn-to-top-in {
  from {
    margin-bottom: -65px;
  }
  to {
    margin-bottom: 0%;
  }
}
