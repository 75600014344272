.videos-bottom-ctn {
  box-sizing: border-box;
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 32px;
	padding: 0 32px;
	margin: 64px 0;
}

.videos-bottom-ctn-video {
	flex: 1 0 40%;
	aspect-ratio: 16/9;
}

.videos-bottom-ctn-title {
	flex: 1 0 100%;
	margin: auto 0;
}

.videos-bottom-title {
	text-align: left;
	font-size: var(--txt-title-sm);
	font-family: 'Bebas Neue', sans-serif;
  	letter-spacing: 1px;
	color: var(--purple);
	margin: 0 0 64px 0;
}

.videos-bottom-description {
	font-size: var(--txt-h5-sm);
	text-align: center;
	line-height: 1.5;
}

@media screen and (min-width: 744px) {
	.videos-bottom-ctn {
		box-sizing: border-box;
		  height: 100%;
		  width: 100%;
		  display: flex;
		  flex-wrap: wrap;
		  gap: 32px;
		  padding: 0 32px;
		  margin: 64px 0;
	  }
	  
	  .videos-bottom-ctn-video {
		  flex: 1 0 40%;
		  aspect-ratio: 16/9;
	  }
	  
	  .videos-bottom-ctn-title {
		  flex: 1 0 50%;
		  margin: auto 0;
	  }
	  
	  .videos-bottom-title {
		  text-align: left;
		  font-size: var(--txt-title-md);
		  font-family: 'Bebas Neue', sans-serif;
		letter-spacing: 1px;
		  color: var(--purple);
		  margin: 0 0 64px 0;
	  }
	  
	  .videos-bottom-description {
		  font-size: var(--txt-h5-md);
		  text-align: justify;
	  }
}

@media screen and (min-width: 1353px) {
	.videos-bottom-ctn {
		box-sizing: border-box;
		  height: 100%;
		  width: 100%;
		  display: flex;
		  flex-wrap: wrap;
		  gap: 32px;
		  padding: 0 32px;
		  margin: 164px 0;
	  }
	  
	  .videos-bottom-ctn-video {
		  flex: 1 0 40%;
		  aspect-ratio: 16/9;
	  }
	  
	  .videos-bottom-ctn-title {
		  flex: 1 0 50%;
		  margin: auto 0;
	  }
	  
	  .videos-bottom-title {
		  text-align: left;
		  font-size: var(--txt-title-xl);
		  font-family: 'Bebas Neue', sans-serif;
		letter-spacing: 1px;
		  color: var(--purple);
		  margin: 0 0 64px 0;
	  }
	  
	  .videos-bottom-description {
		  font-size: var(--txt-h5-xl);
		  text-align: justify;
	  }
}
