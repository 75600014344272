.size-events {
    width: 100%;
    /* margin-left: 20% !important; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  
  .container-bento {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  
  .container-bento .initially-visible {
    flex: 1 1 calc(50% - 20px); /* Ajusta el tamaño de las imágenes */
    margin: 10px;
  }
  
  .lazy-loaded-image-enoturismo {
    width: 100%;
    height: auto;
  }
  
  .enologia-title {
    text-align: center;
    margin-top: 20px;
  }
  .container-events {
    width: 90%; 
  }
  @media (max-width: 768px) {
    .container-bento {
      gap: 22px !important;
    }
  }