.muertos_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    background-color: #481380;
  }
  
  .muertos_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .muertos_col {
    flex: 1 1 30%;
    max-width: 30%;
    box-sizing: border-box;
    padding: 15px;
  }
  
  .muertos_card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: white;
  }
  
  .muertos_card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .muertos_card-info {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .muertos_card-date {
    flex: 0 1 20%;
    font-size: 18px;
    text-align: left;
  }
  
  .muertos_card-details {
    flex: 0 1 75%;
  }
  
  .muertos_card-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .muertos_card-description {
    margin-top: 5px;
    font-size: 14px;
    color: #666;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .muertos_col {
      flex: 1 1 45%;
      max-width: 45%;
    }
  }
  
  @media (max-width: 576px) {
    .muertos_col {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }