.experience-aventure-hero-images-ctn {
  padding: 100px 0 50px 0;
  background-image: url('../../assets/img/header/Fondoamarrillo.webp');
  background-repeat: no-repeat;
  background-size: 100% 50px;
  background-position: top;
}

.experience-aventure-hero-images-ctn-hero {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}

.experience-aventure-hero-images-img-experience-aventure-hero-hero {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}

.experience-aventure-hero-images-title {
  text-align: center;
  font-size: var(--txt-title-sm);
  font-family: 'Bebas Neue', sans-serif;
  letter-spacing: 1px;
  margin-top: 42px;
  color: var(--purple);
}

@media screen and (min-width: 744px) {
  .experience-aventure-hero-images-ctn {
    padding: 180px 0 50px 0;
    background-size: 100% 90px;
  }
  
  .experience-aventure-hero-images-title {
    font-size: var(--txt-title-md);
  }
}

@media screen and (min-width: 1353px) {
  .experience-aventure-hero-images-ctn {
    padding: 200px 0 90px 0;
    background-size: 100% 120px;
  }
  
  .experience-aventure-hero-images-ctn-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
  }
  
  .experience-aventure-hero-images-ctn-hero-description {
    margin-left: 42px;
  }
  
  .experience-aventure-hero-images-img-experience-aventure-hero-hero {
    width: calc(50vw - 32px);
  }
  
  .experience-aventure-hero-images-title {
    font-size: var(--txt-title-xl);
  }
}