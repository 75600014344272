/* Estilos generales */
.lgbt-tabs-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    font-family: Arial, sans-serif;
}

.lgbt-h2 {
    color: #303030!important;
}

.lgbt-tabs-header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.lgbt-tab-button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: #ff4f85; /* Color rosa fuerte */
    color: white;
    margin: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 30px; /* Bordes redondeados */
    font-weight: bold;
    text-transform: uppercase;
}

.lgbt-tab-button.active {
    background-color: #A6218C; /* Color morado cuando está activo */
    color: white;
    transform: scale(1.1); /* Aumenta de tamaño ligeramente cuando está activo */
}

.lgbt-tab-content {
    padding: 20px;
    background-color: #fff;
    border: none;
}

.lgbt-tab-description {
    margin-bottom: 20px;
    font-size: var(--txt-h5-md);
    line-height: 1.5;
    text-align: justify;
    background: var(--grey); /* Establece el color de fondo */
    border-radius: 5px; /* Bordes redondeados */
    margin: 24px 0; /* Márgenes superior e inferior */
    padding: 12px 24px; /* Padding interno */
    line-height: 1.5;
}

.lgbt-images-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
}

.lgbt-image-link {
    flex: 1 1 calc(33.33% - 15px); /* Máximo 3 columnas en pantallas grandes */
    text-decoration: none;
}

/* Asegúrate de que la imagen cubra todo el contenedor */
.lgbt-collage-image {
    width: 100%;
    height: 500px; /* Asegura que la imagen cubra todo el contenedor */
    object-fit: cover; /* Mantiene la relación de aspecto y cubre el contenedor */
    transition: transform 0.3s ease; /* Transición para el efecto de zoom */
}

/* Efecto de zoom en la imagen al pasar el cursor */
.lgbt-image-container:hover .lgbt-collage-image {
    transform: scale(1.1); /* Aumenta el tamaño de la imagen */
    cursor: pointer;
    
}

.lgbt-image-container {
    position: relative;
    width: 100%;
    height: 500px; /* Cambia de 500px a auto para que se ajuste al contenido */
    overflow: hidden; /* Oculta el desbordamiento para redondear las esquinas */
    border-radius: 8px; /* Esquinas redondeadas */
    
}

/* Clase para la imagen de ancho completo */
.lgbt-image-link.full-width {
    flex: 1 1 100%; /* Ocupará el 100% del ancho */
    height: auto; /* Ajusta la altura automáticamente */
}

/* Hover efecto para overlay */
.lgbt-image-container:hover .lgbt-image-overlay {
    opacity: 1;
    cursor: pointer;
}

.lgbt-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro para overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Inicialmente oculto */
    transition: opacity 0.3s ease; /* Transición suave */
}

.lgbt-image-text {
    color: white;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase; /* Para convertir el texto a mayúsculas */
    font-family: 'Bebas Neue', sans-serif; /* Cambia la fuente a Bebas Neue */
    font-size: 52px; /* Aplica el tamaño de fuente de la variable */
}

/* General modal overlay */
.lgbt-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.lgbt-modal-content {
    background-color: white;
    display: flex;
    flex-direction: row;
    max-width: 900px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
}

/* Image section */
.lgbt-modal-image {
    flex: 1;
    max-width: 50%; /* Asegura que la imagen no exceda el 50% del contenedor */
}

.lgbt-modal-img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

/* Text section */
.lgbt-modal-details {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lgbt-modal-title {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333!important;
}

.lgbt-modal-description {
    font-size: 16px;
    color: #555;
}

/* Close button */
.lgbt-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

/* Responsive: Para dispositivos móviles */
@media (max-width: 768px) {
    .lgbt-modal-content {
        flex-direction: column;
        max-width: 90%;
    }

    .lgbt-modal-image {
        max-width: 100%;
    }

    .lgbt-image-link {
        flex: 1 1 100%; /* En móviles, ocupa el 100% */
        height: auto; /* Permite que la altura se ajuste automáticamente */
    }

    .lgbt-image-container {
        min-height: 250px; /* Establece un mínimo si lo deseas */
        height: auto; /* Permite que la altura se ajuste automáticamente */
    }
}

.mt-30{
    margin-top: 30px;
}

.text-center {
    text-align: center;
}