  .container {
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/experiences/bg.webp');
    background-repeat: no-repeat;
    background-position: top; 
    display: block;
    padding: 32px;
  }
  
  .informacion--titulo {
    text-align: center;
    color: var(--yellow);
    font-size:  var(--txt-title-xl);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
    margin: 7px;
  }
  
  .informacion--texto{
    font-size: var(--txt-h5-sm);
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    line-height: 1.5;
    margin: 7px;
  }

  .informacion--subtitulo {
    text-align: center;
    color: var(--yellow);
    font-size:  var(--txt-h1-md);
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 1px;
    margin: 7px;
  }
  .informacion--imagenAdv{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }

  .ButtonContainer {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Asegura que el contenedor esté centrado */
  }
  
  .ButtonContainer img {
    width: 100%;
    height: auto;
  }
  
  .ButtonContainer .btn {
    position: absolute;
    top: 57%;
    left: 20%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: transparent;
    color: black;
    font-size: 16px;
    padding: 14px 30px;
    border: 2px solid black;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    transition: all 0.3s ease; /* Transición suave */
  }
  
  /* Hover */
  .ButtonContainer .btn:hover {
    background-color: black;
    color: white;
    transform: translate(-50%, -50%) scale(1.1); /* Aumenta ligeramente el tamaño */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Agrega sombra */
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .ButtonContainer .btn {
      position: relative;
      top: auto; /* Reestablece la posición en pantallas pequeñas */
      left: 50%;
      transform: translateX(-50%); /* Centra el botón */
      margin-top: 20px; /* Agrega un margen arriba del botón */
      font-size: 14px; /* Reduce el tamaño del texto en pantallas pequeñas */
      padding: 12px 25px; /* Reduce el padding */
      display: block; 
    }
  }
  
  @media (max-width: 480px) {
    .ButtonContainer .btn {
      font-size: 12px; /* Ajuste para pantallas más pequeñas */
      padding: 10px 20px;
    }
  }
