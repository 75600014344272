.cinta-roja {
    width: 100%;
    height: 87px; /* Altura deseada */
    overflow: hidden; /* Para asegurar que la imagen no se desborde */
  }
  
  .cinta-roja-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Para asegurar que la imagen cubra completamente el contenedor */
  }