





/* Contenedor principal */
.geoparqueMexico-container {
	margin: 0 auto;
	max-width: 1100px;
	padding: 64px 0 64px 0;
	background-size: 100% 50px;
	background-position: top;
	overflow: hidden;
  }
  
  /* Título principal */
  .geoparqueMexico-header-title {
	font-size: var(--txt-title-sm);
	color: #95C41D!important;
	font-family: "Bebas Neue", sans-serif;
	letter-spacing: 1px;
	text-align: center;
	margin-bottom: 20px;
  }
  
  /* Párrafos */
  .geoparqueMexico-paragraph {
	margin: 0 auto;
	max-width: 80%;
	text-align: center;
  }
  
  /* Estilo de las columnas */
.geoparqueMexico-columns {
	display: flex; /* Flexbox para crear columnas */
	justify-content: space-between; /* Espaciado entre columnas */
	margin-bottom: 15px; /* Espacio inferior */
	padding: 20px;
	gap: 20px; /* Añade un gap de 20px entre las columnas */
  }
  
  /* Columna izquierda */
  .geoparqueMexico-left-column {
	flex: 1; /* Ocupa espacio proporcional */
	padding-left: 10px;
	padding-right: 10px; /* Espacio a la derecha */
	border: solid 1px #95C41D ;
	border-radius: 10px;
  }
  
  /* Columna derecha */
  .geoparqueMexico-right-column {
	flex: 1; /* Ocupa espacio proporcional */ 
	padding-left: 10px;
	padding-right: 10px; /* Espacio a la derecha */
	padding-top: 10px;
	border: solid 1px #95C41D ;
	border-radius: 10px;
  }
  
  /* Subtítulo */
  .geoparqueMexico-subtitle {
	text-align: center;
	color: #95C41D; /* Color del texto */
	font-size: 1.5rem; /* Tamaño de fuente del subtítulo */
	margin-top: 20px; /* Espacio superior */
	margin-bottom: 10px; /* Espacio inferior */
  }
  
  /* Listas */
  .geoparqueMexico-list,
  .geoparqueMexico-geopark-list {
	list-style-type: disc; /* Estilo de viñetas */
	padding-left: 20px; /* Espacio a la izquierda */
	margin-bottom: 15px;
  }
  
  /* Elementos de la lista */
  .geoparqueMexico-list-item,
  .geoparqueMexico-geopark-item {
	color: #444; /* Color del texto */
	font-size: 20px; /* Tamaño de fuente de los elementos de la lista */
	line-height: 1.5; /* Espaciado entre líneas */
  }
  
  /* Estilos Responsivos */
  @media (max-width: 768px) {
	.geoparqueMexico-columns {
	  flex-direction: column; /* Cambia a una columna en pantallas pequeñas */
	}
  
	.geoparqueMexico-left-column,
	.geoparqueMexico-right-column {
	  padding-right: 0; /* Elimina el padding derecho */
	  padding-left: 0; /* Elimina el padding izquierdo */
	  margin-bottom: 15px; /* Añade espacio entre columnas */
	}
  }

  @media (max-width: 480px) {

	.geoparqueMexico-left-column,
	.geoparqueMexico-right-column {
	  padding-right: 25px; /* Elimina el padding derecho */
	  padding-left: 25px; /* Elimina el padding izquierdo */
	  margin-bottom: 15px; /* Añade espacio entre columnas */
	}
  }