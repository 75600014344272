.acapulcoVideoSlider_video-slider-ctn {
	padding-top: 114px;
	padding-bottom: 114px;
	background-size: 100% 50px;
	background-position: top;
	box-sizing: border-box;
	overflow: hidden;
	max-width: 100%;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	position: relative;
}

.acapulcoVideoSlider_video-slider-ctn p {
	color: #000;
}

.acapulcoVideoSlider_video-slider-ctn-carousel {
	height: 420px;
	position: relative;
	overflow: hidden;
}

.acapulcoVideoSlider_video-slider-ctn-carousel .css-1qzevvg {
	position: absolute !important;
	z-index: 2;
	bottom: 37px;
	left: calc(41.5vw - 20px);
	color: #fff;
	cursor: pointer;
}

.acapulcoVideoSlider_video-slider-ctn-carousel-item-video {
	height: 100%;
	width: 100%;
}

.acapulcoVideoSlider_video-slider-ctn-carousel-item {
	border-radius: 12px;
}

.acapulcoVideoSlider_video-slider-description {
	font-size: var(--txt-h5-xl);
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	font-family: 'Bebas Neue', sans-serif;
	letter-spacing: 2px;
	margin-top: 12px;
}

@media screen and (min-width: 744px) {
	.acapulcoVideoSlider_video-slider-ctn {
		padding: 164px 0 32px 0;
		background-size: 100% 90px;
	}
	
	.acapulcoVideoSlider_video-slider-ctn-carousel {
		height: 470px;
	}
	
	.acapulcoVideoSlider_video-slider-ctn-carousel .css-1qzevvg {
		left: calc(41.5vw - 10px);
	}
}

@media screen and (min-width: 1353px) {
	.acapulcoVideoSlider_video-slider-ctn {
		padding: 194px 0 32px 0;
		background-size: 100% 120px;
	}
	
	.acapulcoVideoSlider_video-slider-ctn-carousel {
		height: 870px;
	}

	.acapulcoVideoSlider_video-slider-ctn-carousel .css-1qzevvg {
		left: calc(41.5vw - 30px);
		
	}
}

@media screen and (min-width: 768px) and (max-width: 1290px) {
	.acapulcoVideoSlider_video-slider-ctn-carousel .css-1qzevvg{
		top: 412.039px !important;
	}
}