
.home-promotion-container{  
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente (si hay altura definida) */
  gap: 20px; /* Espacio entre imágenes */
  flex-wrap: wrap; /* Para que las imágenes se ajusten en pantallas pequeñas */
  padding: 20px;
}

.home-promotion-container a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-promotion-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 600px) {
  .home-promotion-container {
    flex-direction: column;
  }
}