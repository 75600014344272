.header-ctn {
  position: fixed; /* Fija el menú en la parte superior */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 90px;
  align-items: center;
  z-index: 1000; 
  background-color: transparent; /* Fondo transparente al inicio */
  backdrop-filter: blur(5px);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease; /* Transición suave */
} 
.header-ctn.scrolled {
  background-color: white !important;
  background-image: url('../../assets/img//header/textura-menu.png') !important; /* Opcional: añade una textura */
  background-size: contain;
  backdrop-filter: none;
  color: black; 
  height: 16vh;
}
.header-ctn.scrolled nav ul li {
  color: inherit;
}
.header-ctn.scrolled .header-menu-open-img {
  filter: brightness(0); 
}

.input-buscar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.header-ctn-logo {
  top: 0px; left: 10px;
  justify-content: flex-start;
  align-items: center;
}

.header-logo {
  margin-top: 25px;
  width: 180px;
  height: auto;
  z-index: 1000;
}

.header-menu-open {
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.header-menu-open-img {
  height: 40px;
  width: 40px;
}

.header-ctn-menu {
  font-weight: 700;
}

.header-ctn-menu .menu-hide {
  font-weight: 700;
  margin-left: -120vw;
}

.menu-show {
  z-index: 9;
  height: 100vh;
  width: 100vw;
  background: whitesmoke;
  font-weight: 700;
  font-size: 24px;
  padding: 12px;
  margin-left: 0;
  overflow-y: auto;
  overflow-x: hidden;
} 

.header-ctn-menu-ul-secondary {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input-buscar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.header-menu-ul-secondary {
  list-style: none;
  padding: 0 0 12px 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-menu-li-secondary {
  margin: 6px 0;
}

.header-menu-li-secondary a {
  text-decoration: none;
  color: var(--gray);
}

.header-menu-li-secondary a:hover {
  color: var(--grey);
}

.header-menu-li-primary button:hover {
  color: var(--white);
}

.header-ctn-menu-ul-primary {
  width: 100%;
}

.header-menu-ul-primary {
  list-style: none;
  padding: 0;
  
}

.header-menu-li-primary a {
  text-decoration: none;
  color: var(--white);
}

.header-menu-li-primary button {
  cursor: pointer;
  text-decoration: none;
  color: var(--white);
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.header-menu-li-primary a:hover {
  color: var(--white);
}

.react-select-container {
  height: 38px;
  width: 50vw;
  border: none;
  border-radius: 10px !important;
  background: transparent !important;
  color: white;
  font-weight: 700;
  margin: 0 0 14px 0;
  z-index: 1000!important;
}

.react-select__option,
.react-select__menu-list,
.react-select__menu,
.react-select__indicators,
.react-select__value-container,
.react-select__control,
.react-select-container {
  background: rgba(255, 255, 255, 0.07)  !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(2px);
  /* background: var(--purple); */
  cursor: pointer;
  font-size: 14px;
  color: white;
  border-radius: 6px !important;
  z-index: 99;
}

.react-select__indicator,
.lenguage-option {
  color: #000 !important;
}

.header-flag-img {
  height: 24px;
  width: 28px;
  margin-right: 0px;
}

.select-search-container {
  --select-search-background: #fff;
  --select-search-border: var(--gray);
  --select-search-selected: var(--black);
  --select-search-text: var(--black);
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Roboto', sans-serif;
  border-style: none;
  border-radius: 3px;
  position: relative;
  font-family: 'Roboto';
  color: var(--black);
  box-sizing: border-box;
  width: calc(100vw - 24px);
  margin-bottom: 36px;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 38px;
  width: 100%;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  border-radius: 3px;
  outline: none;
  font-family: 'Roboto';
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 18px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.header-ctn-submenu-primary {
  display: none;
}

.show-menu-primary {
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px 48px;
}

.header-ctn-sub-submenu-link-primary {
  display: none;
}

.hide-menu-primary {
  display: none;
}

.show-menu-secondary {
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px 48px;
}

.hide-menu-secondary {
  display: none;
}

.claseImage {
  display: none;
}

.header-submenu-link-img-primary {
  display: none;
}

.header-ctn-submenu-link-primary {
  width: 100%;
}

.menu-hide {
  z-index: 9;
  height: 100vh;
  width: 100%;
  background: whitesmoke;
  font-weight: 700;
  font-size: 24px;
  margin-left: -120vw;
  animation-duration: .5s;
  animation-name: menu-out;
}

.menu-none {
  display: none;
  height: 15vh;
}

.header-ctn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 744px) {
  .header-ctn {
    height: 160px;
    font-size: var(--txt-p-sm);
  }
  .header-ctn-logo {
    position: absolute;
    top: 0px; left: 10px;
    display: flex;
    align-items: center;
    z-index: 1000;
  }
  .header-ctn-language {
    position: absolute;
    top: 30px; right: 10px;
    display: flex;
    align-items: center;
  }

  .header-logo {
    margin-top: 25px;
    width: 180px;
    height: auto;
    z-index: 1000;
  }

  .header-menu-open {
    display: none;
  }

  .header-menu-open-img {
    display: none;
  }

  .header-ctn-menu {
    flex: 1 0 60%;
    margin-left: 28px;
    animation-duration: 0s;
    animation-name: none;
  }

  .header-ctn-menu-ul-secondary {
    justify-content: center;
    align-items: center;
  }

  .header-menu-ul-secondary {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 700px;
    margin: 0;
    border-bottom: none;
    justify-content: right;
    align-items: center;
    padding: 12px 12px 0 0;
  }

  .header-menu-li-secondary {
    margin: 6px 0;
    flex: 1 0 auto;
  }

  .header-menu-select {
    flex: 1 0 auto;
    width: 95px;
    max-width: 95px;
    margin: 0;
    border-radius: 6px;
  }

  .header-ctn-menu-ul-primary {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-menu-ul-primary {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    width: auto;
    margin: 12px 0 30px;
    border-top: 2px solid var(--grey);
    padding-top: 12px;
  }

  .header-menu-li-primary {
    margin: 6px;
    flex: 1 0 auto;
    position: relative;
  }

  .header-menu-li-primary button {
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    justify-content: left;
    display: flex;
  }

  .header-ctn-submenu-primary {
    display: none;
  }

  .header-menu-li-primary:hover .header-ctn-submenu-primary {
    display: block;
    position: absolute;
    width: 450px;
    left: 0;
    height: auto;
    border-radius: 10px;
    padding: 12px;
    z-index: 99;
    background: #fff;
  }

  .header-ctn-submenu-link-primary {
    height: 27px;
    width: 100%;
    display: flex;
  }

  .header-submenu-link-primary {
    height: 100%;
    width: 100%;
    color: #000 !important;
  }

  .header-submenu-link-img-primary {
    display: none;
  }

  .claseImage {
    display: flex;
    height: 85%;
    width: 65%;
    object-fit: cover ;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }

  .header-ctn-submenu-link-primary:hover .header-submenu-link-img-primary {
    display: flex;
    height: 85%;
    width: 65%;
    object-fit: cover ;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }

  .header-ctn-sub-submenu-link-primary {
    display: none;
  }

  .header-ctn-submenu-link-primary:hover .header-ctn-sub-submenu-link-primary {
    display: block;
    position: absolute;
    left: 150px;
    top: 30px;
    height: 50px;
    width: auto;
    background: #fff;
    padding: 12px;
    border-radius: 9px;
    z-index: 1;
    border: 1px solid var(--black);
  }

  .menu-none {
    display: block;
    height: 15vh;

  }

  .select-search-container {
    width: auto;
    margin-bottom: 0;
  }

  .menu-hide {
    z-index: unset;
    overflow: hidden;
    height: 15vh;
    font-size: 16px;
    padding: 0;
    background: transparent;
    animation-duration: 0s;
    animation-name: none;
  }

  .menu-show {
    z-index: unset;
    overflow: visible;
    height: 15vh;
    font-size: 16px;
    padding: 0;
    background: transparent;
  }

  .header-menu-li-primary {
    position: inherit;
  }

  .header-menu-li-primary:hover .header-ctn-submenu-primary {
    right: auto;
    left: auto;
  }

  .solo-mobile {
    display: none;
    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%); */
  } 
  .menu {
    display: grid;
    grid-auto-flow: row; /* Coloca todos los elementos en una sola fila */
    gap: 20px;
    text-align: center;
    margin: 0 auto; /* Centra el menú horizontalmente */
    width: 50%;
  } 
}

@media screen and (min-width: 1353px) and (max-width: 1366px) {
  .header-ctn {
    font-size: var(--txt-p-lg);
    height: auto;
    padding: 12px 24px;
  }

  .header-menu-ul-secondary {
    width: 900px;
  }

  .header-menu-ul-primary {
    gap: 16px;
  }

  .header-menu-li-primary:hover .header-ctn-submenu-primary {
    width: 600px;
    right: 0;
    height: 320px;
    padding: 16px;
  }

  .header-ctn-submenu-link-primary:hover .header-ctn-sub-submenu-link-primary {
    width: 200px;
  }

  .header-ctn-submenu-link-primary:hover .header-submenu-link-img-primary {
    height: 90%;
  }

  .header-menu-li-primary button {
    font-size: 18px;
  }

  .header-menu-select {
    order: 2;
  }

  .select-search-container {
    order: 1;
  }

  .menu-hide,
  .menu-show {
    font-size: 18px;
  }

  .header-menu-li-primary {
    position: relative;
  }

  .menu-show {
    overflow: visible;
  }
}

@keyframes menu-in {
  from {
    margin-left: -100vw;
  }
  to {
    margin-left: 0;
  }
}

@keyframes menu-out {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -110vw;
  }
}

@media screen and (max-width: 743px) {
  .input-buscar, .header-menu-ul-secondary {
    display: none;
  }
  .solo-screen {
    display: none;
  }
  .menu-mobile-container {
    visibility: visible !important;
  }
  .social-media-container {
    width: 20%;
    height: auto;
    top: 70% !important;
    left: 0 !important;
  }
  .social-media-container a {
    width: 30px;
    display: block !important;
  }
  .doll-container a img{
    width: 20% !important;
    position: fixed;
    right: 25px;
    bottom: 10%;
  }
  .header-ctn.scrolled {
    height: 13vh !important;
  }

  .header-ctn-language {
    position: fixed;
    top: 25px;
    right: 20%;
    display: flex;
    align-items: center;
  }

  .header-menu-select {
    flex: 1 0 auto;
    width: 90px;
    max-width: 90px;
    margin: 0;
    border-radius: 6px;
  }
  .header-ctn-logo a{
    width: 50%;
  }
  .header-ctn-logo a img{
    width: 100%;
  }
  .header-menu-open{
    display: block;
    right: 0 !important;
  }
   .header-menu-open{
    position: fixed;
    width: 40px;
    height: 40px;
    top: 25px; right: 15px;
   }
  .header-menu-open-img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
    position: fixed;
    top: 25px; right: 1px;
  }
}

.svg-container{
  position: relative;
  width: 100%;
  max-width: 1200px; 
  padding: 20px;
}
.svg-container svg {
  width: 70%;
  height: auto;
  position: absolute;
  z-index: -1;
  top: 25%;
  left: 0;
  transform: translateY(-50%);
}

.social-media-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.social-media-icons {
  display: flex;
  gap: 10px; 
}

.header-contact-rss {
  width: 50px; 
  height: 50px;
  /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%); */
}

.social-ctn{
  height: auto;
  width: 100% ;
  box-sizing: border-box;
}
.social-ctn-contact {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; 
}
.social-ctn-contact-section {
  flex: 1 0 100%; /* Full width for mobile */
  margin: 0 auto 2px 0;
  align-items: flex-start; 
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1000; /* Asegúrate de que el header esté por encima de otros elementos */
  /* background-color: white; Cambia el color de fondo según tu diseño */
  background-image: url('../../assets/img//header/textura-menu.png') !important; /* Opcional: añade una textura */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  display: inline-block;
}

.menu {
  display: grid;
  grid-auto-flow: column; 
  gap: 20px;
  text-align: center;
  margin: 20px auto; 
  padding-left: 10px;
  width: 65%;
  
} 
.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.menu-item:hover {
  transform: scale(1.1);
}

.icon-menu {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.menu-mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 10px;
  visibility: hidden;
}

.menu-mobile {
  display: grid;
  grid-template-rows: repeat(3, 1fr); /* 3 filas */
  grid-template-columns: repeat(5, 1fr); /* 5 columnas */
  gap: 10px; /* Espacio entre los elementos */
  width: 100%;
  max-width: 500px; 
}

.menu-mobile-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-mobile-item .linkSpan {
  font-size: 10px; 
  color: #333; 
  word-wrap: break-word; 
  text-decoration: none;
}
.icon-menu-mobile {
  width: 100%; 
  height: 50%;
}

.label {
  text-align: center;
  white-space: nowrap; 
  margin-top: 8px; 
  font-size: 0.85rem;
}

.tooltipMenu {
  visibility: hidden;
  width: auto;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  white-space: nowrap; 
}

.menu-item:hover .tooltipMenu {
  visibility: visible;
  opacity: 1;
}
.menu-mobile-item:hover .tooltipMenu {
  visibility: visible;
  opacity: 1;
  top: 410px;
}

.grayLink {
  color: var(--gray);
  text-decoration: none !important;
}
.whiteLink {
  color: var(--white);
  text-decoration: none !important;
}
.no-underline {
  text-decoration: none !important;
}
.social-media-container {
  position:fixed;
  right: 0; 
	top: 200px; 
	z-index: 2000; 
}
.social-media-container a {
  border-radius: 50px;
  display: block;
  color:#fff;
  background: #fff;
  padding: 10px 15px;
  text-decoration: none;
  -webkit-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  transition:all 500ms ease;
}
.doll-container {
  position:fixed;
  right: 0; 
	bottom: 100px;
	z-index: 2000;
}

.doll-container a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  -webkit-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  transition:all 500ms ease; 
}

.has-submenu:hover .submenu-flotante {
  display: block;
}

.submenu-flotante {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;

  background: rgb(255, 255, 255)  !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(2px);

  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 400px; /* Ajusta el ancho según sea necesario */
  height: auto;
  padding: 10px;
}

.submenu-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual tamaño */
  gap: 10px; /* Espacio entre elementos */
}

.submenu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: black;
  padding: 10px;
  text-align: center;
}

.submenu-item:hover {
  background-color: #f0f0f0;
}
.submenu-subtitle {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffc000;
}
.submenu-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 5px;
}