.navigation-menu-container {
  background-color: #fff;
  min-height: 200px;
  padding: 20px;
}

.menu-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.logo {
  font-size: 24px;
}

.menu-text {
  font-size: 18px;
}

.search-input {
  display: flex;
  align-items: center;
}

.search-input input {
  padding: 5px;
}

.secondary-menu,
.primary-menu {
  display: flex;
}

.secondary-menu a,
.primary-menu a {
  margin-right: 20px;
  text-decoration: none;
  color: #000;
}

.mobile-menu-icon {
  display: none;
}

.mobile-menu {
  display: none;
}

/* Agrega más estilos según sea necesario */

@media screen and (max-width: 768px) {
  .menu-text {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .mobile-menu-content {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
  }

  .mobile-menu-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.mobile-menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu-list li {
  margin-bottom: 10px;
}

.mobile-menu-list a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

